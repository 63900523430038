import { useCallback, useRef } from 'react';

export function useDebounce(callback: () => void, delay: number) {
  const handler = useRef<ReturnType<typeof setTimeout>>();

  const debouncedCallback = useCallback(() => {
    if (handler.current) {
      clearTimeout(handler.current);
    }
    handler.current = setTimeout(() => {
      callback();
    }, delay);
  }, [callback, delay]);

  return debouncedCallback;
}
