import { Route, Routes } from 'react-router-dom';
import { PreflightVideoDashboard } from '../VideoDashboard';
import AllPreflights from './All';

const Preflight: React.FC = () => {
  return (
    <Routes>
      <Route index element={<AllPreflights />} />
      <Route path=':id' element={<PreflightVideoDashboard />} />
    </Routes>
  );
};
export default Preflight;
