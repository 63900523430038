import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { parse, unparse } from 'papaparse';
import React, { useState } from 'react';
import FileDropzone from '../../components/FileDropzone';
import { useAuthenticatedUser } from '../../contexts/auth';
import { SocialMediaInfo } from '../../models/experiments';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TikTokPartnershipChecker: React.FC = () => {
  const [results, setResults] = useState<SocialMediaInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [urls, setUrls] = useState('');
  const [error, setError] = useState<string | null>(null);
  const { api } = useAuthenticatedUser();

  const checkPartnership = async (urlList: string[]): Promise<SocialMediaInfo[]> => {
    try {
      const data = await api.experiments.checkpaid(urlList);
      return data;
    } catch (error) {
      console.error('Error checking partnership:', error);
      throw error;
    }
  };

  const processUrls = async (urlList: string[]) => {
    setIsLoading(true);
    setError(null);
    try {
      const checkedUrls = await checkPartnership(urlList);
      setResults(checkedUrls);
    } catch (err) {
      setError('An error occurred while processing URLs');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (file: File) => {
    setIsLoading(true);
    setError(null);
    try {
      const text = await file.text();
      const { data, errors } = parse<{ url: string }>(text, { header: true });
      if (errors.length > 0) {
        throw new Error('Error parsing CSV file');
      }
      await processUrls(data.map(row => row.url));
    } catch (err) {
      setError('An error occurred while processing the file');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUrlSubmit = () => {
    const urlList = urls.split('\n').filter(url => url.trim() !== '');
    processUrls(urlList);
  };

  const onFileSelect = (selectedFile: File) => {
    setFile(selectedFile);
    setResults([]);
    setError(null);
    handleFileUpload(selectedFile);
  };

  const exportToCSV = () => {
    const csvData = results.map(result => ({
      URL: result.url,
      'Video ID': result.videoId,
      'Is Paid Partnership': result.isPaidPartnership ? 'Yes' : 'No',
      Description: result.description,
      baInfo: result.baInfo,
      Username: result.author.username,
      Nickname: result.author.nickname,
      'Play Count': result.stats.playCount,
      'Like Count': result.stats.likeCount,
      'Share Count': result.stats.shareCount,
      'Comment Count': result.stats.commentCount,
      Hashtags: result.hashtags.join(', ')
    }));

    const csv = unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'tiktok_partnership_results.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          TikTok Partnership Checker
        </Typography>
        
        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Option 1: Upload CSV
          </Typography>
          <FileDropzone
            onFileSelect={onFileSelect}
            accept={["text/csv"]}
            file={file}
            onError={() => {
              setFile(null);
              setError('Invalid file type or size');
            }}
            height={150}
            maxFileSize={10 * 1024 * 1024} // 10 MB
          />
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Option 2: Paste URLs
          </Typography>
          <TextField
            value={urls}
            onChange={(e) => setUrls(e.target.value)}
            placeholder="Paste TikTok URLs here, one per line"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUrlSubmit} 
            disabled={isLoading || urls.trim() === ''}
          >
            Check Pasted URLs
          </Button>
        </Box>

        {isLoading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        )}
        
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        
        {results.length > 0 && (
          <Box mt={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Results</Typography>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={exportToCSV}
              >
                Export to CSV
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Video</StyledTableCell>
                    <StyledTableCell>Author</StyledTableCell>
                    <StyledTableCell>Paid</StyledTableCell>
                    <StyledTableCell>Paid By</StyledTableCell>
                    <StyledTableCell>Stats</StyledTableCell>
                    <StyledTableCell>Hashtags</StyledTableCell>
                    <StyledTableCell>mentions</StyledTableCell>
                    <StyledTableCell>Author Allowed to Ads</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Link href={result.url} target="_blank" rel="noopener noreferrer">
                          {result.videoId}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                          {result.description.substring(0, 100)}...
                        </Typography>
                      </StyledTableCell>
            
                      <StyledTableCell>
                        <Typography variant="body2">
                          {result.author.nickname} (@{result.author.username})
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip 
                          label={result.isPaidPartnership ? 'Yes' : 'No'} 
                          color={result.isPaidPartnership ? 'primary' : 'default'}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Link href={"https://www.tiktok.com/@"+result.baInfo} target="_blank" rel="noopener noreferrer">
                          {result.baInfo}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body2">
                          👁 {result.stats.playCount.toLocaleString()}<br />
                          ❤️ {result.stats.likeCount.toLocaleString()}<br />
                          🔁 {result.stats.shareCount.toLocaleString()}<br />
                          💬 {result.stats.commentCount.toLocaleString()}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {result.hashtags.map((tag, i) => (
                          <Chip key={i} label={tag} size="small" style={{ margin: '2px' }} />
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        {result.mentions.map((tag, i) => (
                          <Chip color='warning' key={i} label={tag} size="small" style={{ margin: '2px' }} />
                        ))}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip 
                          label={result.hasAdAuthorization ? 'Yes' : 'No'} 
                          color={result.hasAdAuthorization ? 'primary' : 'default'}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default TikTokPartnershipChecker;