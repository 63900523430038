import {
  ActualType,
  IStructure,
  Position,
  SType,
  SimpleType,
  TimeRange,
  UiType,
} from '.';
import { KeyValueItem, PresenceItem, SimpleList, Tags } from '../creativeData';
import { uiTypeProperties } from './uiTypes';

export type complexUiTypeMap = {
  simpleList: SimpleList;
  unknown: unknown;
  presences: PresenceItem[];
  presenceSummary: PresenceItem[];
  positionMatrix: Position[];
  colorPalette: KeyValueItem<string, number>[];
  tagCloud: KeyValueItem<string, number>[];
  tagsPresence: (PresenceItem<number> & Tags)[];
  calendar: SimpleList<'date'>;
  stackedChart: KeyValueItem<string, number>[];
  barChart: KeyValueItem<string, number>[];
  worldMapChart: KeyValueItem<string, number>[];
  spiderChart: KeyValueItem<string, number>[];
  position: Position;
  timeRange: TimeRange;
};

export const typesMap = {
  string: [
    'text',
    'people',
    'color',
    'timestamp',
    'url',
    'language',
    'tag',
    'uuid',
    'brand',
  ],
  number: ['id', 'confidence', 'duration', 'currency', 'percentage', 'count'],
  boolean: ['boolean'],
  date: ['date'],
  array: [
    'simpleList',
    'colorPalette',
    'presences',
    'tagCloud',
    'stackedChart',
    'presenceSummary',
    'positionMatrix',
    'spiderChart',
    'barChart',
    'worldMapChart',
    'tagsPresence',
    'calendar',
  ],
  object: ['unknown', 'position', 'timeRange'],
} as const;

export const simpleUiTypes = Object.keys(typesMap).reduce(
  (acc, key) => acc.concat(typesMap[key as SimpleType]),
  [] as UiType<SimpleType>[]
);

export function getMappedValue<T extends UiType>(
  structure: IStructure<T>,
  data: any
): T extends keyof complexUiTypeMap
  ? complexUiTypeMap[T]
  : ActualType<SType<T>> {
  if (!structure.mappings || structure.mappings.length === 0) return data;

  const uiType = structure.uiType as T;
  const expectedProps = uiTypeProperties[uiType].expectedProperties;

  if (expectedProps.length === 1) {
    // For simple types with a single expected property
    const mapping = structure.mappings.find(
      m => m.targetProperty === expectedProps[0]
    );
    return (mapping ? data[mapping.sourceProperty] : data) as any;
  } else {
    // For complex types with multiple expected properties
    const mappedData: any = {};
    for (const prop of expectedProps) {
      const mapping = structure.mappings.find(m => m.targetProperty === prop);
      if (mapping) {
        mappedData[prop] = data[mapping.sourceProperty];
      } else {
        mappedData[prop] = data[prop];
      }
    }

    // Handle special cases for array types
    if (structure.type === 'array') {
      const arrayData = Array.isArray(data) ? data : [];
      return arrayData.map((item: any) => {
        const mappedItem: any = {};

        for (const prop of uiTypeProperties[structure.uiType]
          .expectedProperties) {
          const mapping = structure.mappings?.find(
            m => m.targetProperty === prop
          );
          if (mapping) {
            mappedItem[prop] = item[mapping.sourceProperty];
          } else {
            mappedItem[prop] = item[prop];
          }
        }

        return mappedItem;
      }) as any;
    }

    return mappedData as any;
  }
}

const formattersMap: {
  [key in UiType<SimpleType>]?: SFormatter<SType<key>>;
} = {
  boolean: v => (v ? 'Yes' : 'No'),
  confidence: value => `${Number(value).toFixed(2)}%`,
  count: v => `${Number(v).toFixed(2)}`,
  currency: v => `$${Number(v).toFixed(2)}`,
  date: v => new Date(v).toLocaleDateString(),
  duration: v => `${Number(v).toFixed(2)} s`,
  percentage: v => `${Number(v).toFixed(2)}%`,
};

const simpleFormatter: SFormatter<SimpleType> = v => String(v);
export type SFormatter<T extends SimpleType> = (
  v: ActualType<T>
) => React.ReactNode;

export function getSFormatter<U extends UiType<SimpleType>>(
  uiType: U
): SFormatter<SType<U>> {
  return formattersMap[uiType] || simpleFormatter;
}
