import { Box, Typography, styled } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';

interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  setStartDate: (date?: Date) => void;
  setEndDate: (date?: Date) => void;
}
const DateRangePicker: React.FC<DateRangePickerProps> = props => {
  const { startDate, endDate, setStartDate, setEndDate } = props;

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date || undefined);
    if (date && endDate && date > endDate) setEndDate(undefined);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date || undefined);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display='flex' alignItems='center' gap={2}>
        <StyledDatePicker
          label='Start Date'
          value={startDate || null}
          onChange={date => handleStartDateChange(date)}
        />
        <Typography variant='body2'>to</Typography>
        <StyledDatePicker
          label='End Date'
          value={endDate || null}
          onChange={handleEndDateChange}
          minDate={startDate}
        />
      </Box>
    </LocalizationProvider>
  );
};

const StyledDatePicker = styled(DatePicker)(() => ({
  '& .MuiInputBase-root': {
    fontSize: '13px',
  },
  '& input': {
    padding: '7px 10px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '13px',
    lineHeight: '26px',
  },
}));

export default DateRangePicker;
