import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Paper, Popper, List, ListItem, ListItemText } from '@mui/material';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';

interface FormulaProps {
  value: string;
  onChange?: (value: string) => void;
  suggestions?: string[] | null;
}

const Formula: React.FC<FormulaProps> = ({ value, onChange, suggestions }) => {
  const [cursorPosition, setCursorPosition] = useState<{ top: number; left: number } | null>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const editorRef = useRef<HTMLTextAreaElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const textarea = containerRef.current?.querySelector('textarea');
    if (textarea) {
      editorRef.current = textarea;
    }
  }, []);

  useEffect(() => {
    if (editorRef.current) {
      const cursorPosition = editorRef.current.selectionStart;
      const lastWord = value.slice(0, cursorPosition).split(/\W/).pop() || '';
      const filtered = suggestions? suggestions.filter(s => s.toLowerCase().startsWith(lastWord.toLowerCase())) : [];
      setFilteredSuggestions(filtered);
      setShowSuggestions(filtered.length > 0);
    }
  }, [value, suggestions]);

  const handleChange = (code: string) => {
    onChange && onChange(code);
    updateCursorPosition();
  };

  const updateCursorPosition = () => {
    if (editorRef.current) {
      const { selectionStart, selectionEnd } = editorRef.current;
      const rect = editorRef.current.getBoundingClientRect();
      const lineHeight = parseInt(getComputedStyle(editorRef.current).lineHeight);
      const lines = value.substr(0, selectionStart).split('\n');
      const currentLine = lines.length - 1;
      const top = rect.top + lineHeight * currentLine;
      const left = rect.left + (selectionEnd - selectionStart);
      setCursorPosition({ top, left });
    }
  };

  const insertSuggestion = (suggestion: string) => {
    if (editorRef.current) {
      const cursorPosition = editorRef.current.selectionStart;
      const lastWord = value.slice(0, cursorPosition).split(/\W/).pop() || '';
      const newValue = value.slice(0, cursorPosition - lastWord.length) + suggestion + value.slice(cursorPosition);
      onChange && onChange(newValue);
      setShowSuggestions(false);
    }
  };

  return (
    <Box mt={2} mb={2}>
      <Paper elevation={2} style={{ overflow: 'hidden', position: 'relative' }}>
        <div ref={containerRef}>
          <Editor
            value={value}
            onValueChange={handleChange}
            highlight={code => highlight(code, languages.js, 'javascript')}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14,
              minHeight: '100px',
              backgroundColor: '#1e1e1e',
              color: '#d4d4d4',
            }}
            textareaId="codeArea"
            onKeyUp={updateCursorPosition}
            onClick={updateCursorPosition}
          />
        </div>
        <Popper open={showSuggestions} anchorEl={containerRef.current} placement="bottom-start" style={{ zIndex: 1300 }}>
          <Paper>
            <List dense>
              {filteredSuggestions.map((suggestion, index) => (
                <ListItem button key={index} onClick={() => insertSuggestion(suggestion)}>
                  <ListItemText primary={suggestion} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Popper>
      </Paper>
    </Box>
  );
};

export default Formula;