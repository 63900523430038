import { Box, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CreativeDataCard } from '../../components/CreativeDataCards';
import SquareIconCard from '../../components/SquareIconCard';
import {
  ICreativeDataStructure,
  IStructure,
  SType,
  UiType,
} from '../../models/creativeDataStructure';
import { typesMap } from '../../models/creativeDataStructure/typeMaps';
import { uiTypeProperties } from '../../models/creativeDataStructure/uiTypes';
import { PropertyMapper } from './propertyMapper';

interface StructureSectionProps {
  cds: ICreativeDataStructure;
  setStructure: React.Dispatch<React.SetStateAction<IStructure>>;
  jsonData: any;
  path: string;
}

export const StructureSection: React.FC<StructureSectionProps> = React.memo(
  ({ cds, setStructure, jsonData, path }) => {
    const [firstLevelProperties, setFirstLevelProperties] = useState<string[]>(
      []
    );

    const structure = useMemo(() => cds.structure, [cds.structure]);

    const getFirstChild = useCallback((obj: any): any => {
      if (Array.isArray(obj) && obj.length > 0) {
        return obj[0];
      } else if (typeof obj === 'object' && obj !== null) {
        const firstKey = Object.keys(obj)[0];
        return obj[firstKey];
      }
      return obj;
    }, []);

    const getFirstLevelProperties = useCallback((obj: any): string[] => {
      if (typeof obj !== 'object' || obj === null) {
        return [];
      }
      return Object.keys(obj);
    }, []);

    const detectType = useCallback((value: any): SType => {
      if (Array.isArray(value)) return 'array';
      if (value instanceof Date) return 'date';
      if (typeof value === 'object' && value !== null) return 'object';
      if (typeof value === 'string') return 'string';
      if (typeof value === 'number') return 'number';
      if (typeof value === 'boolean') return 'boolean';
      return 'string';
    }, []);

    useEffect(() => {
      const firstChild = getFirstChild(jsonData);
      const properties = getFirstLevelProperties(firstChild);
      setFirstLevelProperties(properties);

      const detectedType = detectType(jsonData);

      setStructure(prevStructure => {
        if (prevStructure.type !== detectedType || !prevStructure.uiType) {
          return {
            ...prevStructure,
            type: detectedType,
            uiType: typesMap[detectedType][0] as UiType,
            subType:
              detectedType === 'array'
                ? uiTypeProperties[typesMap[detectedType][0] as UiType].type ||
                  'string'
                : undefined,
            showLabels: true,
          };
        }
        return prevStructure;
      });
    }, [
      jsonData,
      setStructure,
      getFirstChild,
      getFirstLevelProperties,
      detectType,
    ]);

    const handleUiTypeChange = useCallback(
      (newUiType: UiType) => {
        setStructure(prevStructure => ({
          ...prevStructure,
          uiType: newUiType,
          subType: uiTypeProperties[newUiType].type || 'string',
        }));
      },
      [setStructure]
    );

    const handleShowLabelsChange = useCallback(() => {
      setStructure(prevStructure => ({
        ...prevStructure,
        showLabels: !prevStructure.showLabels,
      }));
    }, [setStructure]);

    return (
      <Stack spacing={2}>
        <TextField
          style={{ display: 'none' }}
          label='Type'
          value={structure.type}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
        <Grid container spacing={2}>
          {typesMap[structure.type].map((uiType: UiType) => {
            const props = uiTypeProperties[uiType];
            return (
              <Grid item key={uiType} display={'flex'}>
                <SquareIconCard
                  icon={props.icon}
                  title={props.title}
                  isSelected={structure.uiType === uiType}
                  onClick={() => handleUiTypeChange(uiType)}
                />
              </Grid>
            );
          })}
        </Grid>
       
        <Box>
          {firstLevelProperties?.length > 0 &&
            uiTypeProperties[structure.uiType].expectedProperties.map(prop => (
              <PropertyMapper
                key={prop}
                propertyName={prop}
                structure={structure}
                setStructure={setStructure}
                availableProperties={firstLevelProperties}
                path={`${path}.${prop}`}
              />
            ))}
        </Box>

        <Box mt={4}>
          <Typography variant='h6' gutterBottom>
            Preview
          </Typography>
          <Box>
            <CreativeDataCard
              creativeData={jsonData}
              cds={cds}
            />
          </Box>
          {structure.type === 'array' && (
          <Grid container spacing={2}>
            <Grid item display={'flex'}>
            <FormControlLabel
              control={
                <Switch
                  checked={structure.showLabels || false}
                  onChange={handleShowLabelsChange}
                />
              }
              label="Show Labels"
            />
          
            </Grid>
          </Grid>
        )}
        </Box>
      </Stack>
    );
  }
);

export default StructureSection;
