import React from 'react';
import { LineChart, Line, ResponsiveContainer, Area, YAxis } from 'recharts';

interface MinimalistChartProps {
  data: number[];
}

const MinimalistChart: React.FC<MinimalistChartProps> = ({ data }) => {
  if (!data) {
    return null
  }
  const chartData = data.map((value, index) => ({ value, index }));

  return (
    <div style={{ width: '100%', height: '100px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData}>
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#3b82f6" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <YAxis domain={['dataMin', 'dataMax']} hide />
          <Area
            type="monotone"
            dataKey="value"
            stroke="none"
            fillOpacity={1}
            fill="url(#colorGradient)"
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#3b82f6"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MinimalistChart;