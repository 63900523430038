import { createTheme, Theme } from '@mui/material/styles';
import { getColor } from './colors';

declare module '@mui/private-theming/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Palette {
    title: {
      main: string;
    };
    gradient: {
      vertical: string;
      horizontal: string;
    };
    blockBackground: {
      ivory: string;
      blue: string;
      lightBlue: string;
      gray: string;
    };
  }
  interface PaletteOptions {
    title?: {
      main?: string;
    };
    gradient?: {
      vertical?: string;
      horizontal?: string;
    };
    blockBackground?: {
      ivory?: string;
      blue?: string;
      lightBlue?: string;
      gray?: string;
    };
  }
}

// Define here custom theme values
const appTheme = createTheme({
  palette: {
    primary: {
      main: getColor(0),
    },
    secondary: {
      main: getColor(1),
    },
    title: {
      main: '#1C1C1C',
    },
    gradient: {
      vertical:
        'linear-gradient(180deg, #EDEFFD 0%, #F6F5EE 47%, #F7F5ED 100%)',
      horizontal:
        'linear-gradient(224deg, #EDEFFD 0%, #F6F5EE 47%, #F7F5ED 100%)',
    },
    blockBackground: {
      ivory: '#E7E1BD',
      blue: '#E3F5FF',
      lightBlue: '#F9FDFF',
      gray: '#E8E8E8',
    },
  },
  typography: {
    fontFamily:
      'Arial, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color emoji',
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#1C1C1C',
          fontSize: '13px',
        },
      },
    },
  },
});

export default appTheme;
