import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NormalizedSelect, {
  FreeNormalizedSelect,
} from '../../components/NormalizedSelect';
import { useAuthenticatedUser } from '../../contexts/auth';
import useGuidelines from '../../contexts/guidelines';
import { platforms } from '../../models';
import { IBrandGuideline, IPlatformGuideline } from '../../models/guidelines';
import { buildNestedSetState } from '../../utils/misc';
import GuidelineManager from './GuidelineManager';

export const CreateBrandGuideline: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { brandGuidelines, createBrandGuideline: createBg } = useGuidelines();
  const navigate = useNavigate();
  const [guideline, setGuideline] = useState<Omit<IBrandGuideline, '_id'>>({
    brand: '',
    categories: [],
  });
  const setBrand = buildNestedSetState(setGuideline, 'brand');
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);

  useEffect(() => {
    const filterBrands = (brands: string[]) =>
      brands.filter(brand => !brandGuidelines.some(g => g.brand === brand));
    api.brands
      .all()
      .then(res => res.map(b => b.BrandName))
      .then(filterBrands)
      .then(setAvailableBrands);
  }, [api, brandGuidelines]);

  const createBrandGuideline = (g: Omit<IBrandGuideline, '_id'>) =>
    createBg(g).then(g => {
      navigate(`../${g._id}`);
      return g;
    });

  return (
    <Stack spacing={2}>
      <h2>Create Brand Guideline</h2>
      <FreeNormalizedSelect
        value={guideline.brand}
        onChange={setBrand}
        options={availableBrands}
        label='Brand'
      />
      <GuidelineManager
        save={createBrandGuideline}
        guideline={guideline}
        setGuideline={setGuideline}
      />
    </Stack>
  );
};

export const CreatePlatformGuideline: React.FC = () => {
  const { platformGuidelines, createPlatformGuideline: createPg } =
    useGuidelines();
  const navigate = useNavigate();
  const availablePlatforms = platforms.filter(
    platform => !platformGuidelines.some(g => g.platform === platform)
  );
  const [guideline, setGuideline] = useState<Omit<IPlatformGuideline, '_id'>>({
    platform: availablePlatforms[0],
    categories: [],
  });
  if (availablePlatforms.length === 0)
    return <Box>No more platforms to create</Box>;

  const setPlatform = buildNestedSetState(setGuideline, 'platform');

  const createPlatformGuideline = (g: Omit<IPlatformGuideline, '_id'>) =>
    createPg(g).then(g => {
      navigate(`../${g._id}`);
      return g;
    });

  return (
    <Stack spacing={2}>
      <h2>Create Platform Guideline</h2>
      <NormalizedSelect
        value={guideline.platform}
        onChange={setPlatform}
        options={availablePlatforms}
        label='Platform'
        noAll
      />
      <GuidelineManager
        save={createPlatformGuideline}
        guideline={guideline}
        setGuideline={setGuideline}
      />
    </Stack>
  );
};
