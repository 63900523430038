import { Optional } from '../utils/typing';
import { ICreativeData } from './creativeData';
import { User, WithRoles } from './users';

export const platforms = [
  'YouTube',
  'Facebook',
  'Instagram',
  'Twitter',
  'TikTok',
] as const;
export type Platform = (typeof platforms)[number];

export const sources = ['internal', 'opera', 'bic'] as const;
export type Source = (typeof sources)[number];

export interface Model<T = string> {
  _id: T;
}

interface MeasureData {
  value: number;
  history: number[];
}

export interface IVideoSummary {
  VideosCount: number;
  VideosDuration: number;
  Measures: {
    Impressions: MeasureData;
    WatchTime: MeasureData;
    Spend: MeasureData;
    CTR: MeasureData;
    ConversionRate: MeasureData;
    Views: MeasureData;
  };
  CreativeData: any;
}

export interface IVideoMetadata {
  name: string;
  assetId: string;
  socialMediaPlatform: Platform;
  brand: string;
  country: string;
  campaignName: string;
  source: Source;
  url: string;
  duration: number;
  description: string;
  publishedAt: string;
  previewUrl?: string;
  previewImage?: string;
  title?: string;
}

export interface IVideoMetric {
  date: Date;
  spendUsd: number;
  spendEur: number;
  views: number;
  impressions: number;
  CTR?: number;
  ConversionRate?: number;
  RetentionRate?: number;
  WatchTime?: number;
}

export interface IVideo extends Model {
  metadata: IVideoMetadata;
  measures: IVideoMetric[];
  creativeData?: ICreativeData;
}
export type IAnalyzedVideo = Required<IVideo> & {
  metadata: Required<IVideoMetadata>;
};
export const perfKeys = [
  'CTR',
  'ConversionRate',
  'RetentionRate',
  'WatchTime',
] as const;
export type PerfKey = (typeof perfKeys)[number];

export type IPreflightVideoCd = {
  general: { videoDuration: number };
  version: string;
} & { [key: string]: any };

export interface IPreflightVideo {
  _id: string;
  platform: Platform;
  brand: string;
  country: string;
  campaignName: string;
  source: Source;
  assetId: string;
  blobName: string;
  name: string;
  objectives: PerfKey[];
  author: WithRoles<User>;
  previewImage?: string;
  previewVideo?: string;
  title?: string;
  creativeData?: IPreflightVideoCd;
  indexation: Indexation;
}
export interface Indexation {
  ci_id: string;
  source: Source;
  asset_id: string;
  brand: string;
  download_url?: string;
  download_status: IndexationStatus;
  video_blob_path?: string;
  ray_status: IndexationStatus;
  ray_id?: string;
  avi_status: IndexationStatus;
  avi_id?: string;
  postprocess_status: IndexationStatus;
  version?: string;
  created_at: Date;
  completed_at?: Date;
}
// !! Order is important here, it will be used to sort the indexations and get the global status
export const indexationStatuses = [
  'completed',
  'pending',
  'processing',
  'failed',
] as const;
export type IndexationStatus = (typeof indexationStatuses)[number];

export type CreatePreflightVideo = Optional<
  Omit<IPreflightVideo, '_id' | 'indexation' | 'blobName' | 'author'>,
  'assetId'
>;
export type IAnalyzedPreflightVideo = Required<IPreflightVideo>;

export function isAnalyzedPreflightVideo(
  video: IPreflightVideo
): video is IAnalyzedPreflightVideo {
  const { previewVideo, previewImage, title, creativeData } = video;
  return !!previewVideo && !!previewImage && !!title && !!creativeData;
}

export function compareIndexation(a: Indexation, b: Indexation) {
  return comparateStatus(a.postprocess_status, b.postprocess_status);
}
function comparateStatus(a: IndexationStatus, b: IndexationStatus) {
  return indexationStatuses.indexOf(a) - indexationStatuses.indexOf(b);
}

export interface Brand {
  BrandID: number;
  BrandName: string;
}
