import { jwtDecode } from 'jwt-decode';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RoleName, User, userHasRole, WithPerms, WithRoles } from '../models/users';
import buildApi, { Api, authApi } from '../services/api';

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (accessToken && refreshToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    }
  }, []);

  useEffect(() => {
    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  }, [accessToken, refreshToken]);

  const login = (email: string, password: string) => {
    setLoading(true);
    return authApi
      .login(email, password)
      .then(({ accessToken, refreshToken }) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
      })
      .catch(err => setError(err.message))
      .finally(() => setLoading(false));
  };

  const refresh = useCallback(async () => {
    if (!refreshToken) return;
    return authApi
      .refreshToken(refreshToken)
      .then(({ accessToken }) => setAccessToken(accessToken))
      .catch(logout);
  }, [refreshToken]);

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
  };

  const authenticatedUser = useMemo(() => {
    if (!accessToken) return null;
    const { user, ...rest } = jwtDecode<TokenPayload>(accessToken);
    return { ...user, ...rest };
  }, [accessToken]);

  useEffect(() => {
    if (authenticatedUser) {
      const { exp } = authenticatedUser;
      const now = Date.now() / 1000;
      setTimeout(refresh, (exp - now - 60) * 1000); // auto refresh token 1 minute before expiration
    }
  }, [authenticatedUser, refresh]);

  const value = {
    accessToken,
    user: authenticatedUser,
    loading,
    error,
    login,
    refreshToken: refresh,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within a AuthProvider');
  return context;
}

export function useAuthentication() {
  const { loading, error, login } = useAuth();
  return { loading, error, login };
}

export function useAuthenticatedUser() {
  const { user, logout, accessToken, refreshToken } = useAuth();
  if (!accessToken || !user) throw new Error('User not authenticated');
  const api: Api = useMemo(
    () => buildApi(accessToken, { on403: refreshToken }),
    [accessToken, refreshToken]
  );
  return {
    user,
    api,
    logout,
    hasRole: (role: RoleName) => userHasRole(user, role),
  };
}

export function useAuthChecks() {
  const { user, accessToken } = useAuth();
  return {
    isAuthenticated: !!user && !!accessToken,
    hasRole: (role: RoleName) => !!user && userHasRole(user, role),
  };
}

interface AuthContextType {
  accessToken: string | null;
  loading: boolean;
  error: string | null;
  user: AuthenticatedUser | null;
  login: (email: string, password: string) => Promise<void>;
  refreshToken: () => Promise<void>;
  logout: () => void;
}

export interface TokenPayload {
  user: WithPerms<WithRoles<User>>;
  iat: number;
  exp: number;
}
export interface AuthenticatedUser extends WithPerms<WithRoles<User>> {
  iat: number;
  exp: number;
}
