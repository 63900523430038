import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import useCdStructures from '../../contexts/cdStructures';
import { ICreativeData } from '../../models/creativeData';
import { ICreativeDataStructure, IStructure } from '../../models/creativeDataStructure';
import { deepClone } from '../../utils/typing';
import buildInferCdsStructure from './inference';

const samplesSize = 5;

interface CdsManagerContextType {
  cdStructures: ICreativeDataStructure[];
  samples: ICreativeData[];
  presentCd: string[];
  absentCd: string[];
  editCds: ICreativeDataStructure | null;
  setEditCds: React.Dispatch<React.SetStateAction<ICreativeDataStructure | null>>;
  cdNameToCreate: string | null;
  setCdNameToCreate: React.Dispatch<React.SetStateAction<string | null>>;
  showCreateModal: boolean;
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
  inferCdsStructure: (cdName: string) => Partial<ICreativeDataStructure>;
  updateCds: (updater: (prev: ICreativeDataStructure) => ICreativeDataStructure) => void;
  setStructure: (updater: (prev: IStructure) => IStructure) => void;
}

const CdsManagerContext = React.createContext<CdsManagerContextType | undefined>(undefined);

const CdsManagerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const { cdStructures, updateCds: updateCdsInContext } = useCdStructures();
  const [samples, setSamples] = useState<ICreativeData[]>([]);
  const [absentCd, setAbsentCd] = useState<string[]>([]);
  const [presentCd, setPresentCd] = useState<string[]>(cdStructures.map(cds => cds.name));
  const [editCds, setEditCds] = useState<ICreativeDataStructure | null>(null);
  const [cdNameToCreate, setCdNameToCreate] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  useEffect(() => {
    api.videos
      .all({ analyzed: true, pageSize: samplesSize })
      .then(res => res.docs.map(v => v.creativeData!))
      .then(setSamples);
  }, [api]);

  useEffect(() => {
    if (!samples.length) return;
    const excludeKeys = ['_id', 'video', '__v'];
    const actualCd = Array.from(
      new Set(samples.flatMap(s => Object.keys(s)))
    ).filter(k => !excludeKeys.includes(k));
    const absentCd = actualCd.filter(
      cd => !cdStructures.find(cds => cds.name === cd)
    );
    setAbsentCd(actualCd);
    setPresentCd(actualCd);
  }, [samples, cdStructures]);

  const updateCds = useCallback((updater: (prev: ICreativeDataStructure) => ICreativeDataStructure) => {
    if (editCds) {
      const updatedCds = updater(deepClone(editCds));
      setEditCds(updatedCds);
      updateCdsInContext(updatedCds);
    }
  }, [editCds, updateCdsInContext]);

  const setStructure = useCallback((updater: (prev: IStructure) => IStructure) => {
    updateCds(prev => ({
      ...prev,
      structure: updater(prev.structure),
    }));
  }, [updateCds]);

  const value = {
    cdStructures,
    samples,
    absentCd,
    presentCd,
    editCds,
    setEditCds,
    cdNameToCreate,
    setCdNameToCreate,
    showCreateModal,
    setShowCreateModal,
    inferCdsStructure: buildInferCdsStructure(samples),
    updateCds,
    setStructure,
  };

  return (
    <CdsManagerContext.Provider value={value}>
      {children}
    </CdsManagerContext.Provider>
  );
};

export default CdsManagerProvider;

export function useCdsManager() {
  const context = React.useContext(CdsManagerContext);
  if (context === undefined) {
    throw new Error(
      'useCdsManager must be used within a CdsManagerProvider'
    );
  }
  return context;
}