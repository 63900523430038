import React, { useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';

const AISearch: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const [vectorQuery, setQuery] = useState('');
  const [searchResponse, setSearchResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.aiSearch.search(vectorQuery);
      setSearchResponse(response);
    } catch (err) {
      setError('An error occurred while searching. Please try again.');
      console.error('Search error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderJsonTree = (data: any, level = 0) => {
    if (typeof data !== 'object' || data === null) {
      return <span className="json-value">{JSON.stringify(data)}</span>;
    }

    return (
      <ul className="json-tree" style={{ paddingLeft: `${level * 20}px` }}>
        {Object.entries(data).map(([key, value]) => (
          <li key={key} className="json-node">
            <span className="json-key">{key}:</span>{' '}
            {renderJsonTree(value, level + 1)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="azure-search">
      <h2>Azure AI Search</h2>
      <div className="search-input">
        <input
          type="text"
          value={vectorQuery}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your search query"
        />
        <button onClick={handleSearch} disabled={isLoading}>
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>

      {error && <p className="error">{error}</p>}

      {searchResponse && (
        <div className="search-results">
          <h3>Search Response:</h3>
          {renderJsonTree(searchResponse)}
        </div>
      )}
    </div>
  );
};

export default AISearch;