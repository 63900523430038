import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useAuthentication } from '../../contexts/auth';

export const Login: React.FC = () => {
  const { login, loading, error } = useAuthentication();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    login(email, password);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      component='form'
    >
      <TextField
        label='Email'
        type='email'
        value={email}
        onChange={e => setEmail(e.target.value)} />
      <TextField
        label='Password'
        type='password'
        value={password}
        onChange={e => setPassword(e.target.value)} />
      <Button onClick={handleLogin} disabled={loading} type='submit'>
        Login
      </Button>
      {error && <Box>{error}</Box>}
    </Box>
  );
};
