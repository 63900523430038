import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InsightDisclaimer: React.FC = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: '#FFF9E6', // Light yellow background
        border: '1px solid #E6D292', // Light golden border
        borderRadius: 2,
        p: 2,
        mb: 3,
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <InfoIcon
        sx={{
          color: '#8B7E57', // Darker shade for the icon
          mr: 2,
          mt: 0.5,
        }}
      />
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          AI Generated Insights
        </Typography>
        <Typography variant="body2">
          The insights generated should not be taken for granted. They reflect patterns based on the scope defined by the user at a specific time, intended to cross-analyze performance with the creative data collected. These insights do not take external factors into account.
        </Typography>
      </Box>
    </Paper>
  );
};

export default InsightDisclaimer;