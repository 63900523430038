import { Person } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const Avatar = styled('img')<{
  width?: number;
  height?: number;
  borderRadius?: string;
}>(({ width = 40, height = 40, borderRadius = '50%' }) => ({
  width,
  height,
  borderRadius,
  objectFit: 'cover',
}));

export type AvatarWithTooltipProps = {
  src?: string;
  alt: string;
  width?: number;
  height?: number;
  marginRight?: string;
};

export const AvatarWithTooltip: React.FC<AvatarWithTooltipProps> = props => {
  const { src, alt, width = 40, height = 40, marginRight = 0 } = props;
  return (
    <Tooltip title={alt} arrow placement='left'>
      <Box
        width={width}
        height={height}
        marginRight={marginRight}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        {src ? (
          <Avatar src={src} alt={alt} width={width} height={height} />
        ) : (
          <Person fontSize='medium' />
        )}
      </Box>
    </Tooltip>
  );
};
