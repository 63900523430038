import { SvgIconComponent } from '@mui/icons-material';
import { Box, BoxProps } from '@mui/material';
import React from 'react';

type DynamicImageProps = {
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
  FallbackIcon?: SvgIconComponent;
} & BoxProps;

const DynamicImage: React.FC<DynamicImageProps> = ({
  src,
  alt,
  FallbackIcon,
  width,
  height,
  ...rest
}) => {
  return (
    <Box {...rest}>
      {src || !FallbackIcon ? (
        <img
        
          src={src}
          alt={alt}
          style={{
            width: width,
            height: height,
            borderRadius: '5px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <FallbackIcon sx={{ width: '100%', height: '100%' }} />
      )}
    </Box>
  );
};

export default DynamicImage;
