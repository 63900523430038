import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IGuidelineScore, IScoreHistory } from '../../models/guidelines';
import GuidelinesScore from '../GuidelinesScore';
import useDashboard from './context';

const GlobalGuidelinesScore: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { filters } = useDashboard();
  const [bgGlobalScore, setBgGlobalScore] = useState<IGuidelineScore>();
  const [pgGlobalScore, setPgGlobalScore] = useState<IGuidelineScore>();
  const [bgScoreHistory, setBgScoreHistory] = useState<IScoreHistory>();
  const [pgScoreHistory, setPgScoreHistory] = useState<IScoreHistory>();
  useEffect(() => {
    api.guidelines.brand.score(filters).then(setBgGlobalScore);
    api.guidelines.platform.score(filters).then(setPgGlobalScore);
    api.guidelines.brand.scoreHistory(filters).then(setBgScoreHistory);
    api.guidelines.platform.scoreHistory(filters).then(setPgScoreHistory);
  }, [api, filters]);



  return (
<Stack width='100%'  direction='column' gap={2} >
    <Stack  width='100%' direction='row' justifyContent={"center"} gap={1} >
   
    {bgGlobalScore && (
      <Box style={{'backgroundColor': '#f3f4f6'}} width={"100%"} p={3}>
        <GuidelinesScore guidelineScore={bgGlobalScore}
        historyData={bgScoreHistory}
         />
      </Box>
    )}
    {pgGlobalScore && (
      <Box style={{'backgroundColor': '#f3f4f6'}} width={"100%"} p={5}>
      <GuidelinesScore
        platform='SocialMedia'
        guidelineScore={pgGlobalScore}
        historyData={pgScoreHistory}
      />
      </Box>
    )}
     </Stack>
    
    </Stack>
  );
};
export default GlobalGuidelinesScore;
