import { Box, BoxProps } from '@mui/material';

interface CenteredSvgProps {
  src: string;
  alt?: string;
  boxProps?: Omit<
    BoxProps,
    'children' | 'display' | 'justifyContent' | 'alignItems'
  >;
  imgProps?: Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'>;
}
const CenteredSvg: React.FC<CenteredSvgProps> = props => {
  const { src, alt, boxProps, imgProps } = props;
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      {...boxProps}
    >
      <img src={src} alt={alt} {...imgProps} />
    </Box>
  );
};
export default CenteredSvg;