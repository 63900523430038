import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Insight } from '../../services/api';
import InsightCards from './InsightsCards'; // Import the existing InsightCards component
import { InsightProvider } from './context';


const SavedInsights: React.FC = () => {
  return (
    <InsightProvider>
      <BookmarkedInsights />
    </InsightProvider>
  );
};

const BookmarkedInsights: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const [bookmarkedInsights, setBookmarkedInsights] = useState<Insight[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookmarkedInsights = async () => {
      try {
        setLoading(true);
        const insights = await api.insights.getBookmarkedInsights();
        setBookmarkedInsights(insights);
      } catch (error) {
        console.error('Error fetching bookmarked insights:', error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarkedInsights();
  }, [api]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (bookmarkedInsights.length === 0) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h6">No bookmarked insights yet.</Typography>
      </Box>
    );
  }

  return (

      <Box sx={{ p: 2 }}>
        
        <Typography variant="h4" sx={{ mb: 2 }}>Bookmarked Insights</Typography>
        <InsightCards
          insights={bookmarkedInsights}
          loading={false}
        />
      </Box>
          
  );
};

export default SavedInsights;