import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { DynamicIcon } from '../features/General/IconPicker';
import { ICreativeData } from '../models/creativeData';

import {
  ICreativeDataStructure
} from '../models/creativeDataStructure';
import { cdIsEmptyOrNull, getGridSize, getNestedValue } from '../utils/misc';
import {
  CdComponentControls,
  CreativeDataComponent,
} from './CreativeDataComponent';

interface CreativeDataCardProps {
  cds: ICreativeDataStructure;
  creativeData: Record<string, any>;
  metadata?: ICreativeData['general'];
  controls?: CdComponentControls;
}

interface CreativeDataCardsProps {
  selectedCd: ICreativeDataStructure[];
  creativeData: Record<string, any>;
  controls?: CdComponentControls;
}


export const CreativeDataCard: React.FC<CreativeDataCardProps> = ({
  cds,
  creativeData,
  metadata,
  controls,
}) => {
  console.log(cds.structure.uiType);
  return (
    <Stack
      sx={{
        borderRadius: '20px',
        background: '#F7F8F2',
        position: 'relative',
        height: '100%',
        m: 0,
      }}
    >
      <Box p={2}>
        <Box
          sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: 40,
          
          height: 40,
          borderRadius: '50%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {cds.icon && <DynamicIcon iconName={cds.icon} />}
      </Box>
      <Typography variant='body1' sx={{ fontWeight: 600, marginBottom: '5px' }}>
        {cds.title}
      </Typography>
      <Typography
        variant='body2'
        sx={{ fontWeight: 300, marginBottom: '20px' }}
      >
        {cds.category}
      </Typography>
      {!cdIsEmptyOrNull(creativeData) ? (
        <Box sx={{ height: '100%' }}>
          {(() => {
            try {
              return (
                <CreativeDataComponent
                  data={creativeData}
                  structure={cds.structure}
                  metadata={metadata}
                  controls={controls}
                />
              );
            } catch (error) {
              return (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  height='100%'
                >
                  <Typography variant='body2' color='error'>
                    An error occurred while displaying this component
                  </Typography>
                </Box>
              );
            }
          })()}
        </Box>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          height='100%'
        >
          <Typography variant='body2'>
            Creative Data not available for this media
          </Typography>
        </Box>
      )}
      </Box>
    </Stack>
  );
};

const CreativeDataCards: React.FC<CreativeDataCardsProps> = ({
  selectedCd,
  creativeData,
  controls,
}) => {
  return (
    <>
      {creativeData &&
        selectedCd
          .sort((a, b) => a.order - b.order)
          .map((cds, i) => (
            <Grid
              item
              xs={12}
              mb={4}
              sm={6}
              md={getGridSize(cds.structure)}
              key={i}
            >
              <CreativeDataCard
                cds={cds}
                creativeData={getNestedValue(creativeData, cds.source)}
                metadata={creativeData.general}
                controls={controls}
              />
            </Grid>
          ))}
    </>
  );
};

export default CreativeDataCards;
