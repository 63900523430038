import React, { CSSProperties, useMemo } from 'react';

interface PositionData {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface PositionHeatmapProps {
  data: PositionData[];
  gridSize?: number;
  videoWidth?: number;
  videoHeight?: number;
}

const styles: Record<string, CSSProperties> = {
  container: {
    width: '100%',
    maxWidth: '1000px',
    margin: '20px auto',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '20px',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    margin: '0 0 10px 0',
  },
  heatmapContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%', // 16:9 aspect ratio
  },
  gridContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    gap: '1px',
    backgroundColor: '#f0f0f0',
  },
  cell: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  cellValue: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '10px',
    color: '#ffffff',
  },
  legend: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
  },
  legendBox: {
    width: '16px',
    height: '16px',
    borderRadius: '2px',
  },
  stats: {
    marginTop: '20px',
    padding: '10px',
    borderRadius: '4px',
    fontSize: '14px',
  }
};

const PositionHeatmap: React.FC<PositionHeatmapProps> = ({ 
  data,
  gridSize = 20,
  videoWidth = 640,
  videoHeight = 360
}) => {
  // Calculate cell dimensions
  const cellWidth = videoWidth / gridSize;
  const cellHeight = videoHeight / gridSize;

  // Create heatmap data structure
  const heatmapData = useMemo(() => {
    const grid = Array(gridSize).fill(0).map(() => Array(gridSize).fill(0));
    
    data.forEach(item => {
      const startX = Math.floor(item.x / cellWidth);
      const endX = Math.min(Math.floor((item.x + item.width) / cellWidth), gridSize - 1);
      const startY = Math.floor(item.y / cellHeight);
      const endY = Math.min(Math.floor((item.y + item.height) / cellHeight), gridSize - 1);
      
      for (let x = startX; x <= endX; x++) {
        for (let y = startY; y <= endY; y++) {
          if (x >= 0 && x < gridSize && y >= 0 && y < gridSize) {
            grid[y][x]++;
          }
        }
      }
    });
    
    return grid;
  }, [data, gridSize, cellWidth, cellHeight]);

  // Calculate statistics
  const stats = useMemo(() => {
    const flatData = heatmapData.flat();
    const maxValue = Math.max(...flatData);
    const totalObjects = data.length;
    const occupiedCells = flatData.filter(value => value > 0).length;
    const coveragePercentage = (occupiedCells / (gridSize * gridSize) * 100).toFixed(1);

    return {
      maxValue,
      totalObjects,
      occupiedCells,
      coveragePercentage
    };
  }, [heatmapData, data, gridSize]);

  // Function to get cell color based on value
  const getCellColor = (value: number) => {
    const intensity = value / stats.maxValue;
    return `rgba(0, 0, 255, ${Math.min(0.1 + intensity * 0.9, 1)})`;
  };

  return (
    <>
      <div style={styles.heatmapContainer}>
        <div style={{
          ...styles.gridContainer,
          gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
        }}>
          {heatmapData.flat().map((value, index) => (
            <div
              key={index}
              style={{
                ...styles.cell,
                backgroundColor: getCellColor(value),
              }}
            >
              
            </div>
          ))}
        </div>
      </div>

      <div style={styles.legend}>
        <div style={styles.legendItem}>
          <div style={{...styles.legendBox, backgroundColor: 'rgba(0, 0, 255, 0.1)'}} />
          <span>Low</span>
        </div>
        <div style={styles.legendItem}>
          <div style={{...styles.legendBox, backgroundColor: 'rgba(0, 0, 255, 0.5)'}} />
          <span>Medium</span>
        </div>
        <div style={styles.legendItem}>
          <div style={{...styles.legendBox, backgroundColor: 'rgba(0, 0, 255, 1)'}} />
          <span>High</span>
        </div>
      </div>

      <div style={styles.stats}>
        <div>Coverage: {stats.coveragePercentage}%</div>
      </div>
    </>
  );
};

export default PositionHeatmap;