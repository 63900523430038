import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {
  Box,
  IconButton
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IMomentResult } from '../../models/moments';
import VideoDetails from './VideoDetails';
import VideoTimeline from './VideoTimeline';

interface VideoPlayerProps {
  result: IMomentResult;
  onClose: () => void;
}



const VideoPlayer: React.FC<VideoPlayerProps> = ({ result, onClose }) => {
  const video = result.video;
  const metadata = video.metadata;
  //convert to milliseconds
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  
  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime * 1000);
    }
  };

  const handleSeek = (time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time / 1000;
      setCurrentTime(time);
    }
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <>    
    <IconButton
      onClick={onClose}
      sx={{ width: 40 }}
    >
      <ArrowBackIcon />
    </IconButton>
      <Box sx={{ position: 'relative', display: "flex" }}>
        <Box sx={{ width: '100%',maxHeight: '700px' }}>
          <video
            ref={videoRef}
            src={metadata.previewUrl}
            controls={false}
            style={{ width: '100%', height: 'auto',maxHeight: '500px' }}
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => setIsPlaying(false)}
          />
          <Box width={"100%"}>
            <VideoTimeline
              result={result}
              currentTime={currentTime}
              onSeek={handleSeek}
              onPlayPause={handlePlayPause}
              isPlaying={isPlaying}
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%', pl: 2 }}>
        <VideoDetails  result={result} />
        </Box>
      </Box>
    </>
  );
};

export default VideoPlayer;