import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { PresenceItem } from '../../models/creativeData';



interface PresenceTimelineProps {
  data: PresenceItem[];
}

const PresenceSummaryChart: React.FC<PresenceTimelineProps> = ({ data }) => {
  return (
    <ChartContainer>
      
        <Typography variant="h3" fontSize={100} p={2} fontWeight="bold">{data.length}</Typography>
      
      <ContentWrapper>
        <AvatarContainer>
          {data.map((item, index) => (
            <AvatarWrapper key={item.label} index={index} length={data.length}>
              <Avatar src={item.image || `/api/placeholder/50/50`} alt={item.label} />
              <Typography key={item.label} width={'60%'} variant="body2">{item.label}</Typography>
            </AvatarWrapper>
          ))}
        </AvatarContainer>
      </ContentWrapper>
    </ChartContainer>
  );
};

const ChartContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});



const AvatarContainer = styled(Box)({
  position: 'relative',
  height:'140px',
});

const AvatarWrapper = styled(Box)<{ index: number,length:number }>(({ index,length }) => ({
  position: 'absolute',
  left: `${index * (200/length)}px`,
  zIndex: 3 - index,
  display:'flex',
  flexDirection:'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: '10px',
}));

const Avatar = styled('img')({
  width: '140px',
  height: '140px',
  borderRadius: '50%',
  objectFit: 'cover',
});

const NamesContainer = styled(Box)({
  display: 'flex',
  gap: '16px',
});

export default PresenceSummaryChart;