import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import MomentsLogo from '../../assets/mlogo.svg';
import CenteredSvg from '../../components/CenteredSvg';
import VidCard from '../../components/VidCard';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IMomentResult } from '../../models/moments';
import VideoPlayer from './VideoPlayer';
const StyledBadge = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
}));

const Moments: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const [query, setQuery] = useState<string>('');
  const [selectedVideo, setSelectedVideo] = useState<IMomentResult | null>(null);
  const [results, setResults] = useState<IMomentResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const handleSearch = async () => {
    setIsLoading(true);
    setResults([]);
    setSelectedVideo(null);
    setHasSearched(true);
    try {
      const fetchedResults = await api.moments.search({ query });
      console.log('Fetched results:', fetchedResults);
      setResults(fetchedResults);
    } catch (error) {
      console.error('Error fetching results:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box sx={{ boxShadow: "none", width: "100%", border: 'none', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box 
        sx={{ 
          display: "flex", 
          flexDirection: hasSearched ? "row" : "column",
          justifyContent: hasSearched ? "flex-start" : "center",
          height: hasSearched ? "40px" : "auto",
          alignItems: "center",
          flexGrow: hasSearched ? 0 : 1,
          transition: 'all 0.3s ease-in-out',
          padding: hasSearched ? '20px' : 0,
          gap: '24px',
        }}
      >
        <CenteredSvg 
          src={MomentsLogo} 
          alt='Experiments Banner' 
        
          imgProps={{
            style: {
              width: hasSearched ? '200px' : '500px',
              marginRight: hasSearched ? '20px' : '0px',
              transition: 'all 0.3s ease-in-out'
            }
          }}
        />
        
        {!hasSearched && (
          <>
            <Typography variant="h3" component="h1" sx={{ 
              fontSize: '28px', 
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 2
            }}>
              AI POWERED SEARCH
            </Typography>
            <Typography variant="h6" sx={{ 
              textAlign: 'center', 
              maxWidth: '800px',
              mb: 4,
              color: '#666'
            }}>
              Find any moment in any video instantly. Just describe what you're looking for
              in natural language, and our AI takes you straight there.
            </Typography>
          </>
        )}
        
        <Box style={{ 
          width: hasSearched ? '50%' : '60%',
          maxWidth: '800px', 
          display: "flex", 
          flexDirection: "column", 
          alignItems: "center" 
        }}>
          <TextField
            placeholder="Type your search here"
            variant='outlined'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#666' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                  
                    onClick={handleSearch}
                    sx={{
                      height: '60px',
                      width: '120px',
                      bgcolor: '#ef2f6f',
                      color: 'white',
                      textTransform: 'none',
                      borderRadius: '0 35px',
                      px: 4,
                      '&:hover': {
                        bgcolor: '#FF3377'
                      }
                    }}
                  >
                    Find
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ 
              backgroundColor: '#F8F9FD', 
              borderRadius: '30px',
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                '& fieldset': {
                  borderColor: 'transparent'
                },
                '&:hover fieldset': {
                  borderColor: 'transparent'
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent'
                }
              }
            }}
          />
        </Box>
      </Box>

      {hasSearched && (
        <Box sx={{ flexGrow: 1, overflow: 'auto', padding: '0 20px' }}>
          {selectedVideo && (
            <VideoPlayer result={selectedVideo} onClose={() => setSelectedVideo(null)} />
          )}

          <Box style={{ width: "100%" }} sx={{ mt: 4 }}>
            {results.length > 0 && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h5" component="h2">
                    Results
                  </Typography>
                  <IconButton color="inherit">
                    <FilterListIcon />
                  </IconButton>
                </Box>
              </>
            )}

            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <img width="64" src="/loading.gif" alt="Loading..." />
              </Box>
            ) : hasSearched && results.length === 0 ? (
              <Typography variant="body1" sx={{ textAlign: 'center', mt: 4 }}>
                No results found. Please try a different search term.
              </Typography>
            ) : (
              <Grid container spacing={3} sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                {results.map((result) => {
                  const metadata = result.video.metadata;
                  return (
                    <Grid item xs={12} sm={6} md={4} key={result.video._id}>
                      <VidCard metadata={metadata} id={result.video._id} onClick={() => setSelectedVideo(result)} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Box>
        </Box>
      )}

      
    </Box>
  );
};

export default Moments;