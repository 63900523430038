import { Box, CircularProgress, circularProgressClasses, Typography } from '@mui/material';
import React from 'react';

interface CircularScoreProps {
  score: number;
  title: string;
  labelPosition?: 'left' | 'middle';
  thickness?: number;
  typographyVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
  size?: number | string;
}

const getColorGradient = (score: number): string => {
  // Pastel color palette, , 
  const lowColor = [231, 111, 81];  // Pastel red
  const highColor = [42, 157, 143]; // Pastel green

  const r = Math.round(lowColor[0] + (highColor[0] - lowColor[0]) * (score / 100));
  const g = Math.round(lowColor[1] + (highColor[1] - lowColor[1]) * (score / 100));
  const b = Math.round(lowColor[2] + (highColor[2] - lowColor[2]) * (score / 100));

  return `rgb(${r}, ${g}, ${b})`;
};

const CircularScore: React.FC<CircularScoreProps> = ({
  score,
  title,
  labelPosition = 'middle',
  thickness = 6,
  typographyVariant = 'body1',
  size,
}) => {
  const color = getColorGradient(score);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box position="relative" display="inline-flex" gap={1} justifyContent={'center'} alignItems={'center'} >
        {labelPosition == 'left' && <Typography variant={typographyVariant} color="text.secondary" sx={{ mt: 1, height: '100%',fontSize: '1.6rem',paddingRight: '10px', color: color,fontWeight: 600 }}>
          {`${Math.round(score)}%`}
        </Typography>}
        <Box sx={{ position: 'relative' }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size={size}
            thickness={thickness}
            sx={{
              color: (theme) => theme.palette.grey[200],
              position: 'absolute',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
          />
          <CircularProgress
            variant="determinate"
            value={score}
            size={size}
            thickness={thickness}
            sx={{
              color: color,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {labelPosition == 'middle' && <Typography variant={typographyVariant} color="text.secondary" sx={{ mt: 1 }}>
            {`${Math.round(score)}%`}
          </Typography>}

         
        </Box>
      </Box>
    </Box>
  );
};

export default CircularScore;