import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import CircularScore from '../../components/CircularScore';
import { IGuidelineScore } from '../../models/guidelines';
import { getColor } from '../../theme/colors';
import { DynamicIcon } from '../General/IconPicker';
import MinimalistChart from '../../components/Charts/MinimalistChart';

interface GuidelinesScoreProps {
  platform?: string;
  guidelineScore: IGuidelineScore;
  historyData?: DynamicArray;
}
type DynamicArray = {
  [key: string]: number[]
};


const GuidelinesScore: React.FC<GuidelinesScoreProps> = ({ guidelineScore, platform,historyData }) => {
  const dataset = guidelineScore.scores.map(({ score, ...rest }) => ({
    score: score * 100,
    ...rest,
  }));
  return (
    <Stack direction="column" spacing={2} alignItems="center" width={"100%"}  >
    <Stack direction="row" spacing={8} alignItems="center" width={"100%"}  >
      <Stack width="100%" >
   
      { platform && <Box display={"flex"} alignItems={"center"} gap={2} marginBottom={2}><DynamicIcon iconName={platform} style={{ color: "#000", width: "30px", height: "30px" }} /> {platform} Guidelines</Box>}
      { !platform && <Box display={"flex"} alignItems={"center"} gap={2} marginBottom={2}><DynamicIcon iconName={"CorporateFareRounded"} style={{ color: "#000", width: "30px", height: "30px" }} /> Brand Guidelines</Box> }
        <Stack spacing={2}>
 
          {dataset.map((item, index) => (
            <Box key={item.name} position="relative" height={40}>
              {/* Background grey bar */}
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#e0e0e0',
                  borderRadius: '10px',
                  padding: '0 15px',
                }}
              />
              {/* Colored score bar */}
              <Box
                sx={{
                  position: 'absolute',
                  width: `${item.score}%`,
                  height: '100%',
                  backgroundColor: getColor(index),
                  borderRadius: '10px',
                  display: 'flex',
                  WordBreak: 'keep-all',
                  alignItems: 'center',
                  padding: '0 15px',
                }}
              >
                 {item.icon && <DynamicIcon iconName={item.icon} style={{ color: "#fff", width: "20px", height: "20px", marginRight: '8px' }} />}
                 <Typography variant="body2" color="white" fontWeight="medium" noWrap overflow={"visible"}>
                  {item.name}
                </Typography>
              </Box>
              {/* Score percentage */}
              <Typography 
                variant="body2" 
                sx={{ 
                  position: 'absolute', 
                  right: -40, 
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'text.secondary'
                }}
              >
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack style={{display:"flex", justifyContent:"center",flexDirection:"column"}}>
        <CircularScore 
          title= {platform ? platform + ' Score' : 'Brand Score'}
          score={guidelineScore.totalScore * 100} 
          size={100} 
        />
        
      </Stack>
      
    </Stack>
    {(historyData) && 
          <Stack height={50} width={"100%"}>
           <MinimalistChart data={historyData["Avg"]} />
          </Stack>}
    </Stack>

  );
};

export default GuidelinesScore;