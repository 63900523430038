import { ExpandMoreOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteProps,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  styled,
  TextField,
  TextFieldVariants,
  Typography,
} from '@mui/material';

type SupportedType = string | readonly string[] | number | undefined;
type NormalizedSelectProps<T extends SupportedType> = Omit<
  SelectProps<T>,
  'onChange' | 'children' | 'renderValue'
> & {
  onChange: (value: T) => void;
  options: readonly T[];
  noAll?: boolean;
  formatOption?: (option: T) => string;
  width?: number | string;
  underlined?: boolean;
  parseValue?: (value: string) => T;
};
const NormalizedSelect = <T extends SupportedType>(
  props: NormalizedSelectProps<T>
) => {
  const {
    variant = 'standard',
    value,
    onChange,
    options,
    width = 120,
    sx,
    label,
    noAll,
    underlined = false,
    formatOption = (option: T) => option as string,
    parseValue = v => v as T,
    ...rest
  } = props;
  return (
    <FormControl variant={variant} sx={{ width }}>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        value={value || ''}
        label={label}
        disableUnderline={!underlined}
        onChange={e => onChange(parseValue(e.target.value))}
        IconComponent={props => (
          <ExpandMoreOutlined
            {...props}
            fontSize='small'
            sx={{ color: theme => `${theme.palette.title.main} !important` }}
          />
        )}
        sx={{
          width,
          ...sx,
        }}
        {...rest}
        renderValue={selected => (
          <SelectRenderValue
            value={selected}
            label={label}
            formatOption={formatOption}
          />
        )}
      >
        {!noAll && <MenuItem value={undefined}>All</MenuItem>}
        {options.map((option, index) => (
          <MenuItem
            key={index}
            component='li'
            value={option}
            sx={{ textTransform: 'capitalize' }}
          >
            {formatOption(option)}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};
export default NormalizedSelect;

type MultipleNormalizedSelectProps<T extends SupportedType> = Omit<
  SelectProps<T[]>,
  'onChange' | 'children'
> & {
  onChange: (value: T[]) => void;
  options: readonly T[];
  formatOption?: (option: T) => string;
  width?: string | number;
  underlined?: boolean;
  parseValue?: (value: string) => T;
};
export const MultipleNormalizedSelect = <T extends SupportedType>(
  props: MultipleNormalizedSelectProps<T>
) => {
  const {
    variant = 'standard',
    value,
    onChange,
    options,
    width = 120,
    sx,
    label,
    underlined = false,
    formatOption = (option: T) => option as string,
    parseValue = v => v as T,
    ...rest
  } = props;
  return (
    <FormControl variant={variant} sx={{ width }}>
      <InputLabel>{label}</InputLabel>
      <StyledSelect
        multiple
        value={value || []}
        label={label}
        variant={variant}
        displayEmpty
        disableUnderline={!underlined}
        onChange={e => onChange(e.target.value.map(parseValue))}
        {...rest}
        IconComponent={props => (
          <ExpandMoreOutlined
            {...props}
            fontSize='small'
            sx={{ color: theme => `${theme.palette.title.main} !important` }}
          />
        )}
        sx={{
          width,
          ...sx,
        }}
        renderValue={selected => (
          <SelectRenderValue
            value={selected}
            label={label}
            formatOption={formatOption}
          />
        )}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            component='li'
            value={option}
            sx={{ textTransform: 'capitalize' }}
          >
            {formatOption(option)}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

const SelectRenderValue: React.FC<{
  value: any;
  label: React.ReactNode;
  formatOption?: (option: any) => string;
}> = props => {
  const { value, label, formatOption = v => v } = props;
  let title;
  if (Array.isArray(value)) {
    if (!value || !value.length) return <FormLabel>{label}</FormLabel>;
    title = value.map(formatOption).join(', ');
  } else {
    if (!value) return <FormLabel>{label}</FormLabel>;
    title = formatOption(value);
  }
  return (
    <Typography
      title={title}
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
    >
      {title}
    </Typography>
  );
};

const StyledSelect = styled(Select)<SelectProps<any>>(({ theme }) => ({
  minWidth: 120,
  textTransform: 'capitalize',
  fontSize: '13px',
  // color: theme.palette.title.main,
  '& :focus': {
    backgroundColor: `${theme.palette.blockBackground.lightBlue} !important`,
  },
}));

type FreeNormalizedSelectProps<T extends string> = Omit<
  AutocompleteProps<string, false, false, true>,
  'onChange' | 'options' | 'renderInput' | 'freeSolo'
> & {
  variant?: TextFieldVariants;
  onChange: (value: string) => void;
  options: readonly T[];
  label?: string;
};
export function FreeNormalizedSelect<T extends string>(
  props: FreeNormalizedSelectProps<T>
) {
  const {
    variant = 'standard',
    value,
    onChange,
    options,
    sx,
    label,
    ...rest
  } = props;
  return (
    <FormControl variant={variant}>
      <Autocomplete
        inputValue={value || ''}
        onInputChange={(_, value) => onChange(value)}
        options={options}
        renderInput={params => (
          <TextField variant={variant} {...params} label={label} />
        )}
        freeSolo
        {...rest}
      />
    </FormControl>
  );
}
