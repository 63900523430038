import { GridView, ViewList } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import NormalizedSelect from '../../components/NormalizedSelect';
import { sources } from '../../models';
import { StyledFiltersBar } from '../../theme/bars';
import useLibrary from './context';

const FiltersBar: React.FC = () => {
  const { filters, setFilter, availableFilters } = useLibrary();
  const { platform, brand, country, campaign } = filters;
  const { platforms, brands, countries, campaigns } = availableFilters;
  return (
    <StyledFiltersBar direction='row' spacing={5} alignItems='center' flexShrink={0}>
      <NormalizedSelect
        label='Platform'
        value={platform}
        onChange={setFilter('platform')}
        options={platforms}
      />
      <NormalizedSelect
        label='Brand'
        value={brand}
        onChange={setFilter('brand')}
        options={brands}
      />
      <NormalizedSelect
        label='Country'
        value={country}
        onChange={setFilter('country')}
        options={countries}
      />
      <NormalizedSelect
        label='Campaign'
        value={campaign}
        onChange={setFilter('campaign')}
        options={campaigns}
      />
      <NormalizedSelect
        label='Source'
        value={filters.source}
        onChange={setFilter('source')}
        options={sources}
      />
      <DisplayMode />
    </StyledFiltersBar>
  );
};
export default FiltersBar;

const DisplayMode: React.FC = () => {
  const { displayMode, setDisplayMode } = useLibrary();
  return (
    <Stack direction='row' spacing={1}>
      <IconButton
        onClick={() => setDisplayMode('grid')}
        color={displayMode === 'grid' ? 'primary' : 'default'}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        }}
      >
        <GridView />
      </IconButton>
      <IconButton
        onClick={() => setDisplayMode('list')}
        color={displayMode === 'list' ? 'primary' : 'default'}
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
          },
        }}
      >
        <ViewList />
      </IconButton>
    </Stack>
  );
};
