import { Box, Pagination, Typography } from '@mui/material';
import useLibrary from './context';

const PaginationBar: React.FC = () => {
  const { pagination, setPage } = useLibrary();
  const { currentPage, totalPages, totalDocs } = pagination;
  return (
    <Box
      justifyContent='space-between'
      alignItems='flex-end'
      display='flex'
      p={1}
    >
      <Typography variant='body1'>
        {totalDocs > 0 && `${totalDocs} items`}
      </Typography>

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(_, page) => setPage(page)}
        sx={{
          '& .MuiPaginationItem-root': {
            borderRadius: '10px',
          },
          '& .Mui-selected': {
            backgroundColor: theme => theme.palette.blockBackground.gray,
          },
        }}
      />
    </Box>
  );
};
export default PaginationBar;
