import CategoryIcon from "@mui/icons-material/Category";
import ImageIcon from "@mui/icons-material/Image";
import PeopleIcon from "@mui/icons-material/People";

import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import FileDropzone from "../../components/FileDropzone";
import { useAuthenticatedUser } from "../../contexts/auth";
import {
  OCaptionAnalysis,
  OClassifyAnalysis,
  OFaceAnalysis,
  OperaEndpointMap
} from "../../models/opera";

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  "& svg": {
    marginRight: theme.spacing(1),
  },
}));

const ImagePreview = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "8px",
  marginTop: "16px",
  marginBottom: "16px",
  objectFit: "cover",
  maxHeight: "300px",
});

type ImageAnalysisResult = OperaEndpointMap & { inferenceTime: number };

const ImageAnalysisComponent: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [results, setResults] = useState<ImageAnalysisResult | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onFileSelect = async (selectedFile: File) => {
    setFile(selectedFile);
    setResults(null);
    setError(null);
    setIsLoading(true);

    // Create a preview URL for the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result as string);
    };
    reader.readAsDataURL(selectedFile);

    try {
      const analysisResults = await api.imageAnalysis.analyzeAll(selectedFile);
      setResults(analysisResults);
    } catch (error) {
      console.error("Error analyzing image:", error);
      setError(
        "An error occurred while analyzing the image. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack pt={1} spacing={2}>

      <Typography variant="h4">Augmented DAM</Typography>
      <Stack direction="row" spacing={2} width="100%">
        <Box flexBasis={"400px"}>
          <Box sx={{ bgcolor: "#f0f4ff", padding: "30px", position: "relative" }}>
            <FileDropzone
              onFileSelect={onFileSelect}
              accept={["image/*"]}
              file={file}
              onError={() => {
                setFile(null);
                setPreviewUrl(null);
              }}
              height={300}
              maxFileSize={10 * 1024 * 1024} // 10 MB
            />
            {previewUrl && (
              <Box
                sx={{
                  position: "absolute",
                  top: "30px",
                  left: "30px",
                  right: "30px",
                  bottom: "30px",
                  zIndex: -1,
                }}
              >
                <ImagePreview
                  src={previewUrl}
                  alt="Selected image preview"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
  
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        </Box>
        <Results results={results} isLoading={isLoading} />
      </Stack>
    </Stack>
  );
};

export default ImageAnalysisComponent;

const Results: React.FC<{ results: ImageAnalysisResult | null, isLoading: boolean }> = ({
  results,
  isLoading
}) => {

  if (!results) return (
    <Stack flexGrow={1} m="20px" spacing={2}>
    {isLoading && (
      <Box
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "64px",
          height: "64px",
          zIndex: 1000,
        }}
      >
        <img width="64" src="/loading.gif" alt="Loading..." />
      </Box>
    )}
    </Stack>
  );
  const { face, caption, classify, inferenceTime } = results;
  return (
    <Stack flexGrow={1} m="20px" spacing={2}>

      <OperaCaption caption={caption} />
      <OperaClassify classify={classify} />
      <OperaFace face={face} />
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Inference Time: {(inferenceTime * 1000).toFixed(2)} ms
      </Typography>
    </Stack>
  );
};

const OperaFace: React.FC<{ face: OFaceAnalysis }> = ({ face }) => {
  return (
    <AnalysisBox>
      <CardTitle variant="h5">
        <PeopleIcon sx={{ color: "#8080ff" }} /> People Detected
      </CardTitle>
      {face.people.length === 0 && (
        <Typography variant="body2">
          No people detected in the image.
        </Typography>
      )}
      <List>
        {face.people.map((person, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText
                primary={`Person ${index + 1}`}
                secondary={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      maxHeight: "100px",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <b>Name:</b> {person.name}
                    </Typography>
                    <Typography variant="body2">
                      <b>Age: </b> {person.age}
                    </Typography>
                    <Typography variant="body2">
                      <b>Gender: </b> {person.gender}
                    </Typography>
                    <Typography variant="body2">
                      <b>Profession: </b> {person.profession}
                    </Typography>
                    <Typography variant="body2">
                      <b>Nationality: </b> {person.nationality}
                    </Typography>
                    <Typography variant="body2">
                      <b>Is Ambassador: </b>{" "}
                      {person.isAmbassador ? "Yes" : "No"}
                    </Typography>
                    <Typography variant="body2">
                      <b>Confidence: </b> {person.confidence.toFixed(2)}
                    </Typography>                  
                  </Box>
                }
              />
            </ListItem>
            {index < face.people.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <Typography variant="body2" sx={{ mt: 2 }}>
        <b>Inference Time:</b> {face.inferenceTime.toFixed(2)} ms
      </Typography>
    </AnalysisBox>
  );
};

const OperaClassify: React.FC<{ classify: OClassifyAnalysis }> = ({
  classify,
}) => {
  return (
    <AnalysisBox>
      <CardTitle variant="h5">
        <CategoryIcon sx={{ color: "#8080ff" }} /> Image Classification
      </CardTitle>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>

      {classify.label.split(" - ").map((item, index) => (
        <Box
          key={index}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'primary.main',
            color: 'white',
            borderRadius: '8px',
            padding: '16px',
            minWidth: '200px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
            transition: 'background-color 0.2s',
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          <Typography
            variant="caption"
            sx={{
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              opacity: 0.75,
              marginBottom: 1
            }}
          >
            {["Subtype", "View"][index]}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500
            }}
          >
            {item}
          </Typography>
        </Box>
      ))}
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        <b>Inference Time:</b> {classify.inferenceTime.toFixed(2)} ms
      </Typography>
    </AnalysisBox>
  );
};

const OperaCaption: React.FC<{ caption: OCaptionAnalysis }> = ({ caption }) => {
  const tags = caption.tags.split(',').map(tag => tag.trim());
  return (
    <AnalysisBox>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <ImageIcon sx={{ color: "#8080ff", mr: 1 }} />
          <Typography variant="h5" component="h2">
            Image Caption
          </Typography>
        </Box>

        <Typography sx={{ mb: 2 }}>
          <strong>Description:</strong> {caption.description}
        </Typography>

        <Box>
          <Typography component="span" sx={{ fontWeight: 'bold', display: 'block', mb: 1 }}>
            Tags:
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                sx={{
                  bgcolor: 'primary.light',
                  color: 'white',
                  '&:hover': {
                    bgcolor: 'primary.main',
                  }
                }}
              />
            ))}
          </Box>
        </Box>

    
    </AnalysisBox>
  );
};

const AnalysisBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#f3f4f6",
  padding: "20px",
  borderRadius: "10px",
  // borderRadius: 5,
  // padding: theme.spacing(3),
  // marginBottom: theme.spacing(3),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
}));
