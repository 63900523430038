import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useBrands } from '../../contexts/global';
import { User, WithPerms, WithRoles } from '../../models/users';
import { parseApiError } from '../../services/api';
import useUsersMgr from './context';

const AllUsers: React.FC = () => {
  const { getBrand } = useBrands();
  const { users } = useUsersMgr();

  const columns: GridColDef<WithPerms<WithRoles<User>>>[] = [
    { field: 'Email', flex: 2.5 },
    { field: 'Username', flex: 2 },
    {
      field: 'IsActive',
      headerName: 'Active',
      flex: 1,
      renderCell: ({ row }) => (row.IsActive ? 'Yes' : 'No'),
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'BrandID',
      headerName: 'Brand',
      flex: 2,
      renderCell: ({ row }) => getBrand(row.BrandID)?.BrandName,
    },
    {
      field: 'Roles',
      flex: 2,
      renderCell: ({ row }) => row.Roles.map(role => role.RoleName).join(', '),
    },
    {
      field: 'Permissions',
      flex: 5,
      renderCell: ({ row }) =>
        row.Permissions.map(permission => permission.PermissionName).join(', '),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      flex: 1,
      renderCell: ({ row }) => <Actions user={row} />,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Stack height='100%'>
      <Stack direction='row' py={2} spacing={2}>
        <Button
          variant='contained'
          color='primary'
          startIcon={<AddCircle />}
          component={NavLink}
          to='create'
        >
          New User
        </Button>
      </Stack>
      <Box flexGrow={1}>
        <DataGrid
          rows={users}
          columns={columns}
          disableRowSelectionOnClick
          getRowId={row => row.UserID}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
            columns: {
              columnVisibilityModel: {
                started: false,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            '--DataGrid-containerBackground': 'transparent !important',
            '--DataGrid-rowBorderColor': 'transparent !important',
            border: 'none',
            '& .MuiDataGrid-row.preflightAnalyzed': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: '#FAFAFA',
              borderRadius: '30px',
            },
          }}
        />
      </Box>
    </Stack>
  );
};
export default AllUsers;

const Actions: React.FC<{
  user: WithPerms<WithRoles<User>>;
}> = ({ user }) => {
  const { deleteUser } = useUsersMgr();
  return (
    <Box height='100%' display='flex' alignItems='center'>
      <Stack direction='row' width='100%' justifyContent='space-evenly'>
        <IconButton
          size='small'
          color='secondary'
          sx={{ p: 0.5 }}
          component={NavLink}
          to={`edit/${user.UserID}`}
        >
          <Edit />
        </IconButton>
        <IconButton
          size='small'
          color='error'
          sx={{ p: 0.5 }}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this user?'))
              deleteUser(user.UserID).catch(err => alert(parseApiError(err)));
          }}
        >
          <Delete />
        </IconButton>
      </Stack>
    </Box>
  );
};
