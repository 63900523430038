import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingRessourcePage from '../../components/LoadingRes';
import { useAuthenticatedUser } from '../../contexts/auth';
import {
  IAnalyzedVideo,
  IPreflightVideo,
  isAnalyzedPreflightVideo,
  IVideoMetadata,
} from '../../models';
import { ICreativeData } from '../../models/creativeData';
import Creatives from './Creatives';
import Metadata from './Metadata';
import useVideoDashboard, { VideoDashboardProvider } from './context';


const VideoDashboard: React.FC = () => {
  const { id } = useParams();
  if (!id) throw new Error('No ID provided');
  const { api } = useAuthenticatedUser();
  const [video, setVideo] = useState<IAnalyzedVideo>();
  useEffect(() => {
    api.videos.one(id).then(setVideo);
  }, [api, id]);
  if (!video) return <LoadingRessourcePage resName='Analysis' />;

  return (
    <VideoDashboardProvider video={video}>
      <InnerVideoDashboard />
    </VideoDashboardProvider>
  );
};
export default VideoDashboard;

// TODO wont work completely until dedicated guidelines endpoints for preflight videos are implemented
export const PreflightVideoDashboard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error('No id provided');
  const { api } = useAuthenticatedUser();
  const [preflight, setPreflight] = useState<IPreflightVideo | null>(null);
  useEffect(() => {
    api.preflight.one(id).then(setPreflight);
  }, [api, id]);
  if (!preflight) return <LoadingRessourcePage resName='Preflight' />;
  if (!isAnalyzedPreflightVideo(preflight))
    throw new Error('Preflight video is not analyzed');

  const metadata: Required<IVideoMetadata> = {
    name: preflight.name,
    assetId: preflight._id,
    socialMediaPlatform: preflight.platform,
    brand: preflight.brand,
    country: preflight.country,
    campaignName: preflight.campaignName,
    source: preflight.source,
    url: '',
    duration: 0,
    description: '',
    publishedAt: '',
    previewUrl: preflight.previewVideo,
    previewImage: preflight.previewImage,
    title: preflight.title,
  };
  const video: IAnalyzedVideo = {
    _id: preflight._id,
    metadata,
    measures: [],
    creativeData: preflight.creativeData as ICreativeData,
  };
  return (
    <VideoDashboardProvider video={video}>
      <InnerVideoDashboard />
    </VideoDashboardProvider>
  );
};

const InnerVideoDashboard: React.FC = () => {
  const { video, seekTime } = useVideoDashboard();
  return (
    <Box>
      <Metadata />

      <Creatives />
    </Box>
  );
};
