import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingRessourcePage from '../../components/LoadingRes';
import { useAuthenticatedUser } from '../../contexts/auth';
import useGuidelines from '../../contexts/guidelines';
import { IBrandGuideline, IPlatformGuideline } from '../../models/guidelines';
import GuidelineManager from './GuidelineManager';

export const BrandGuideline: React.FC = () => {
  const { id } = useParams();
  if (!id) throw new Error('No id provided');
  const { api } = useAuthenticatedUser();
  const [guideline, setGuideline] = useState<IBrandGuideline>();
  const { updateBrandGuideline } = useGuidelines();
  useEffect(() => {
    api.guidelines.brand.one(id).then(setGuideline);
  }, [api, id]);
  if (!guideline) return <LoadingRessourcePage resName='Brand Guideline' />;
  return (
    <Box>
      <h2>Brand: {guideline.brand}</h2>
      <GuidelineManager
        guideline={guideline}
        setGuideline={setGuideline as Dispatch<SetStateAction<IBrandGuideline>>}
        save={updateBrandGuideline}
      />
    </Box>
  );
};

export const PlatformGuideline: React.FC = () => {
  const { id } = useParams();
  if (!id) throw new Error('No id provided');
  const { api } = useAuthenticatedUser();
  const [guideline, setGuideline] = useState<IPlatformGuideline>();
  const { updatePlatformGuideline } = useGuidelines();
  useEffect(() => {
    api.guidelines.platform.one(id).then(setGuideline);
  }, [api, id]);
  if (!guideline) return <LoadingRessourcePage resName='Platform Guideline' />;
  return (
    <Box>
      <h2>Platform: {guideline.platform}</h2>
      <GuidelineManager
        guideline={guideline}
        setGuideline={
          setGuideline as Dispatch<SetStateAction<IPlatformGuideline>>
        }
        save={updatePlatformGuideline}
      />
    </Box>
  );
};
