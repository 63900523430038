import { Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import CircularScore from '../../components/CircularScore';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IGuidelineScore } from '../../models/guidelines';
import GuidelinesScore from '../GuidelinesScore';
import useVideoDashboard from './context';

const GuidelinesScores: React.FC = () => {
  return (
    <Stack
      flexDirection={'row'}
      gap={5}
      height={'100%'}
      style={{ padding: '10px 40px' }}
    >
      <BrandGuidelinesScore />
      <PlatformGuidelinesScore />
    </Stack>
  );
};

export default GuidelinesScores;

export const BrandMinimalisticScore: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { video } = useVideoDashboard();
  console.log("video", video);
  const [guidelineScore, setGuidelineScore] = useState<IGuidelineScore>();

  useEffect(() => {
    api.videos.scores
      .brand(video._id)
      .then(setGuidelineScore)
      .catch(handleAxiosError({ print: true }));
  }, [api, video._id]);
  console.log("guidelineScore", guidelineScore);
return <CircularScore
  title={''}
  score={(guidelineScore?.totalScore || 0) * 100 || 0} 
  size={60} 
  labelPosition={'left'}
/>  
};

const BrandGuidelinesScore: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { video } = useVideoDashboard();
  const [guidelineScore, setGuidelineScore] = useState<IGuidelineScore>();

  useEffect(() => {
    api.videos.scores
      .brand(video._id)
      .then(setGuidelineScore)
      .catch(handleAxiosError({ print: true }));
  }, [api, video._id]);

  if (!guidelineScore) return null;
  return <GuidelinesScore guidelineScore={guidelineScore} />;
};

const PlatformGuidelinesScore: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { video } = useVideoDashboard();
  const [guidelineScore, setGuidelineScore] = useState<IGuidelineScore>();

  useEffect(() => {
    api.videos.scores
      .platform(video._id)
      .then(setGuidelineScore)
      .catch(handleAxiosError({ print: true }));
  }, [api, video._id]);

  if (!guidelineScore) return null;
  return (
    <GuidelinesScore
      platform={video.metadata.socialMediaPlatform}
      guidelineScore={guidelineScore}
    />
  );
};

const handleAxiosError =
  (opt: { print: boolean } = { print: false }) =>
  (error: AxiosError<any>) => {
    const msg = error.response?.data?.error || error.message;
    if (opt.print) console.error(msg);
  };
