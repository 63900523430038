import { PaletteMode, Theme, darken, lighten } from '@mui/material';

import { SystemStyleObject } from '@mui/system';
const tropicalIndigo = '#8080FF';
const salmonPink = '#FF8080';
const chromeYellow = '#FFA500';
const aquaMarine = '#7FFFD4';
const blueViolet = '#8A2BE2';
const black = '#000000';

export const baseColors = [
  tropicalIndigo,
  salmonPink,
  chromeYellow,
  blueViolet,
  aquaMarine,
  black,
] as const;

export function getColor(index: number): string {
  return baseColors[index % baseColors.length];
}

export const deriveColor = (color: string, mode: PaletteMode = 'light') => {
  const background = mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  const hover = mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  const selected = mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  const selectedHover =
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);
  const css: SystemStyleObject<Theme> = {
    backgroundColor: background,
    '&:hover': {
      backgroundColor: hover,
    },
    '&.Mui-selected': {
      backgroundColor: selected,
      '&:hover': {
        backgroundColor: selectedHover,
      },
    },
  };
  return { background, hover, selected, selectedHover, css };
};
