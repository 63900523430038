import DateRangePicker from '../../components/DateRangePicker';
import NormalizedSelect from '../../components/NormalizedSelect';
import { sources } from '../../models';
import { StyledFiltersBar } from '../../theme/bars';
import useDashboard from './context';

const CreativesFilters: React.FC = () => {
  const { filters, setFilter, availableFilters } = useDashboard();
  const { platform, brand, country, source, start, end } = filters;
  const { platforms, brands, countries } = availableFilters;
  return (
    <StyledFiltersBar direction='row' spacing={5} alignItems='center'>
      <NormalizedSelect
        label='Platform'
        value={platform}
        onChange={setFilter('platform')}
        options={platforms}
      />
      <NormalizedSelect
        label='Brand'
        value={brand}
        onChange={setFilter('brand')}
        options={brands}
      />
      <NormalizedSelect
        label='Country'
        value={country}
        onChange={setFilter('country')}
        options={countries}
      />
      <NormalizedSelect
        label='Source'
        value={source}
        onChange={setFilter('source')}
        options={sources}
      />
      <DateRangePicker
        startDate={start}
        endDate={end}
        setStartDate={setFilter('start')}
        setEndDate={setFilter('end')}
      />
    </StyledFiltersBar>
  );
};
export default CreativesFilters;
