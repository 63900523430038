import { YouTube } from '@mui/icons-material';
import SocialIcon from '@mui/icons-material/InterestsOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import ConversionIcon from '@mui/icons-material/Refresh';
import BrandIcon from '@mui/icons-material/Storefront';
import ScoreIcon from '@mui/icons-material/TaskAlt';
import WatchTimeIcon from '@mui/icons-material/Timer10Outlined';
import { BrandMinimalisticScore } from './GuidelinesScore';

import {
  Stack,
  SvgIconProps,
  Theme,
  Typography
} from '@mui/material';
import React from 'react';
import useVideoDashboard from './context';
const styles = {
  card: {
    width: '100%',
    backgroundColor: '#f3f4f6',
    borderRadius: '8px',
    padding: '16px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column' as const,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  title: {
    color: '#4b5563',
    fontWeight: 500,
    fontSize: '16px',
    margin: 0,
  },
  icon: {
    color: '#9ca3af',
  }
};
const formatValue = (value: number): string => {
  const roundedValue = Math.round(value * 10) / 10; // Round to 1 decimal place

  if (roundedValue >= 1000000000) {
    return (roundedValue / 1000000000).toFixed(1) + 'B';
  } else if (roundedValue >= 1000000) {
    return (roundedValue / 1000000).toFixed(1) + 'M';
  } else if (roundedValue >= 1000) {
    return (roundedValue / 1000).toFixed(1) + 'K';
  } else {
    return roundedValue.toFixed(1);
  }
}

const Metadata: React.FC = () => {
  const { video } = useVideoDashboard();
  const  metadata = video.metadata;
  const { country, socialMediaPlatform, brand } = metadata;
  const lastMeasure = video.measures.sort(
    (a, b) => a.date.valueOf() - b.date.valueOf()
  )[0];
  const { views, impressions } = lastMeasure || {
    views: undefined,
    impressions: undefined,
  };
  return (

    
    <Stack direction='row' spacing={2} p={2}>
      <MetaCard
        title='Platform'
        value={socialMediaPlatform}
        icon={<YouTube />}
        
      />
      <MetaCard title='Brand' value={brand} />
      <MetaCard title='Location' value={country} />
      <MetaCard title='Impressions' value={formatValue(impressions)} />
      <MetaCard title='Views' value={formatValue(views)} />
      <MetaCard title='Score' value={""} />
    </Stack>
  );
};
export default Metadata;

type MetaCardProps = {
  title: string;
  value: string | number;
  icon?: React.ReactElement;
  color?: string;
  background?: 'blue' | 'gradient';
};
const MetaCard: React.FC<MetaCardProps> = props => {
  const { title, value, icon, color, background = 'blue' } = props;
  

  const getBackgroundColor = (theme: Theme) => {
    switch (background) {
      case 'gradient':
        return theme.palette.gradient.vertical;
      case 'blue':
        return theme.palette.blockBackground.blue;
    }
  };

  return (
    <div
      style={styles.card}
    >
      <div style={styles.header}>
        <Typography
          sx={styles.title}
        >
          {title}
        </Typography>
        {/* icon and value */}
        <span style={styles.icon}>
          {title === 'Platform' && <SocialIcon />}
          {title === 'Location' && <LocationIcon />}   
          {title === 'Impressions' && <ConversionIcon />}
          {title === 'Views' && <WatchTimeIcon />}
          {title === 'Brand' && <BrandIcon />}
          {title === 'Score' && <ScoreIcon />}
        </span>
        </div>
        <Stack direction='row' spacing={1} alignItems={'center'} height={'100%'}>
          {icon && <IconWrapper icon={icon} size={20} />}
          <Typography
            color={color}
            sx={{ fontWeight: 600, 
              fontSize: '1.4rem', letterSpacing: '0.1px'
              , textTransform: 'capitalize' }}
          >
            {value}
          </Typography>
          {title === 'Score' && <BrandMinimalisticScore />}
        </Stack>
    </div>
  );
};

interface IconWrapperProps extends SvgIconProps {
  icon: React.ReactElement;
  size?: number | string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  size = 20,
  ...props
}) => {
  return React.cloneElement(icon, {
    ...props,
    style: { fontSize: size, ...props.style },
  });
};
