import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IBrandGuideline, IPlatformGuideline } from '../models/guidelines';
import { useAuthenticatedUser } from './auth';

const GuidelinesContext = createContext<GuidelinesContextType | null>(null);

export const GuidelinesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { api } = useAuthenticatedUser();
  const [brandGuidelines, setBrandGuidelines] = useState<IBrandGuideline[]>([]);
  const [platformGuidelines, setPlatformGuidelines] = useState<
    IPlatformGuideline[]
  >([]);

  useEffect(() => {
    api.guidelines.brand.all().then(setBrandGuidelines);
    api.guidelines.platform.all().then(setPlatformGuidelines);
  }, [api]);

  const createBrandGuideline = (guideline: Omit<IBrandGuideline, '_id'>) =>
    api.guidelines.brand.create(guideline).then(created => {
      setBrandGuidelines(prev => [...prev, created]);
      return created;
    });

  const updateBrandGuideline = (guideline: IBrandGuideline) =>
    api.guidelines.brand.update(guideline).then(updated => {
      setBrandGuidelines(prev =>
        prev.map(g => (g._id === updated._id ? updated : g))
      );
      return updated;
    });

  const createPlatformGuideline = (
    guideline: Omit<IPlatformGuideline, '_id'>
  ) =>
    api.guidelines.platform.create(guideline).then(created => {
      setPlatformGuidelines(prev => [...prev, created]);
      return created;
    });

  const updatePlatformGuideline = (guideline: IPlatformGuideline) =>
    api.guidelines.platform.update(guideline).then(updated => {
      setPlatformGuidelines(prev =>
        prev.map(g => (g._id === updated._id ? updated : g))
      );
      return updated;
    });

  const value = {
    brandGuidelines,
    platformGuidelines,
    createBrandGuideline,
    updateBrandGuideline,
    createPlatformGuideline,
    updatePlatformGuideline,
  };
  return (
    <GuidelinesContext.Provider value={value}>
      {children}
    </GuidelinesContext.Provider>
  );
};

export default function useGuidelines() {
  const context = useContext(GuidelinesContext);
  if (!context) throw new Error('useAuth must be used within a AuthProvider');
  return context;
}

interface GuidelinesContextType {
  brandGuidelines: IBrandGuideline[];
  platformGuidelines: IPlatformGuideline[];
  createBrandGuideline: (
    guideline: Omit<IBrandGuideline, '_id'>
  ) => Promise<IBrandGuideline>;
  updateBrandGuideline: (
    guideline: IBrandGuideline
  ) => Promise<IBrandGuideline>;
  createPlatformGuideline: (
    guideline: Omit<IPlatformGuideline, '_id'>
  ) => Promise<IPlatformGuideline>;
  updatePlatformGuideline: (
    guideline: IPlatformGuideline
  ) => Promise<IPlatformGuideline>;
}
