import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as MuiIcons from '@mui/icons-material';
import { Delete, Edit, GridView, Save, TableView, Undo } from '@mui/icons-material';
import { Box, Button, Paper, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { CreativeDataCard } from '../../components/CreativeDataCards';
import useCdStructures from '../../contexts/cdStructures';
import {
  ICreativeDataStructure,
  isCds,
} from '../../models/creativeDataStructure';
import { deriveColor } from '../../theme/colors';
import { getGridSize, getNestedValue } from '../../utils/misc';
import { useCdsManager } from './context';

interface SortableCardProps {
  cds: ICreativeDataStructure;
  index: number;
  creativeData: any;
}

const SortableCard = ({ cds, index, creativeData }: SortableCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: cds._id ?? '' });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    height: '100%',
    margin: 0,
    zIndex: isDragging ? 1000 : 'auto',
    position: isDragging ? 'relative' as const : 'static' as const
  };

  return (
    <Box
      ref={setNodeRef}
      sx={style}
      {...attributes}
      {...listeners}
    >
      <CreativeDataCard
        cds={cds}
        creativeData={creativeData}
      />
    </Box>
  );
};

const DroppablePlaceholder = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Paper
      sx={{
        width: 450,
        height: 450,
        m: 1,
  
        backgroundColor: 'rgba(0,0,0,0.05)',
        border: '2px dashed rgba(0,0,0,0.2)',
      }}
    >
      {children}
    </Paper>
  );
};

const CdsDataGrid: React.FC = () => {
  const { cdStructures, setEditCds, presentCd, updateCds } = useCdsManager();
  const { deleteCds } = useCdStructures();
  const { samples } = useCdsManager();
  const [viewMode, setViewMode] = useState<'table' | 'card'>('table');
  const [orderedCards, setOrderedCards] = useState(cdStructures);
  const [hasOrderChanged, setHasOrderChanged] = useState(false);
  const [originalOrder, setOriginalOrder] = useState(cdStructures);

  useEffect(() => {
    setOrderedCards(cdStructures);
    setOriginalOrder(cdStructures);
  }, [cdStructures]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const validCds = (cds: ICreativeDataStructure) => {
    return isCds(cds) && presentCd.includes(cds.name);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = orderedCards.findIndex(cds => cds._id === active.id);
    const newIndex = orderedCards.findIndex(cds => cds._id === over.id);

    // Use arrayMove helper from dnd-kit to swap positions
    const reorderedItems = arrayMove(orderedCards, oldIndex, newIndex);

    // Update order property for all items
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      order: index
    }));

    // Update state with new order
    setOrderedCards(updatedItems);
    setHasOrderChanged(true);
  };

  const handleSaveOrder = () => {
    orderedCards.forEach(card => {
      updateCds(prev => ({
        ...prev,
        ...(prev._id === card._id ? { ...card } : {})
      }));
    });
    setHasOrderChanged(false);
  };

  const handleResetOrder = () => {
    setOrderedCards(originalOrder);
    setHasOrderChanged(false);
  };

  const renderIcon = (params: GridRenderCellParams) => {
    const IconComponent = MuiIcons[params.value as keyof typeof MuiIcons];
    return IconComponent ? (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <IconComponent fontSize="small" />
      </Box>
    ) : null;
  };

  const columns: GridColDef<ICreativeDataStructure>[] = [
    {
      field: 'icon',
      headerName: '',
      width: 50,
      renderCell: renderIcon,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'title', headerName: 'Title', flex: 1.5 },
    { field: 'category', headerName: 'Category', flex: 1.5 },
    {
      field: 'structure',
      headerName: 'UiType',
      valueGetter: (v: ICreativeDataStructure['structure']) => v.uiType,
      flex: 1,
    },
    {
      field: 'display',
      headerName: 'Visbile',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'highlighted',
      headerName: 'Highlighted',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'isFactor',
      headerName: 'Is Factor',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'showInDashboard',
      headerName: 'Dashboard',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'order',
      headerName: 'Order',
      type: 'number',
      flex: 1,
    },
    { field: 'description', headerName: 'Description', flex: 5 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      cellClassName: 'actions',
      getActions: s => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label='Edit'
            className='textPrimary'
            onClick={e => setEditCds(s.row)}
            color='inherit'
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label='Delete'
            onClick={e => deleteCds(s.id as string)}
            color='inherit'
          />,
        ];
      },
    },
  ];

  const renderCardView = () => {
    const highlightedCards = [...orderedCards]
      .filter(cds => cds.highlighted)
      .sort((a, b) => a.order - b.order);

    return (
      <Box py={2}>
        <DndContext
          sensors={sensors}
          collisionDetection={rectIntersection}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={highlightedCards.filter(cds => cds._id).map(cds => cds._id!)}
            strategy={verticalListSortingStrategy}
          >
            <Box width={'100%'} display="flex" flexWrap="wrap"  justifyContent="center" flexDirection="column" >
              {samples.length > 0 && highlightedCards.map((cds, index) => {
                const size = getGridSize(cds.structure);
    
                return (
                  <DroppablePlaceholder key={cds._id}>
                    <SortableCard
                      cds={cds}
                      index={index}
                      creativeData={getNestedValue(samples[3], cds.source)}
                    />
                  </DroppablePlaceholder>
                );
              })}
            </Box>
          </SortableContext>
        </DndContext>
      </Box>
    );
  };

  return (
    <Box height='100%' display="flex" flexDirection="column" gap={2} position="relative">
      <Box display="flex" justifyContent="flex-end">
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={(_, newMode) => newMode && setViewMode(newMode)}
          size="small"
        >
          <ToggleButton value="table">
            <TableView />
          </ToggleButton>
          <ToggleButton value="card">
            <GridView />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      
      {viewMode === 'table' ? (
        <DataGrid
          rows={cdStructures}
          columns={columns}
          getRowId={row => row._id ?? ''}
          getRowClassName={params => (validCds(params.row) ? 'valid' : 'error')}
          sx={theme => ({
            '& .error': deriveColor(theme.palette.error.main, theme.palette.mode)
              .css,
          })}
        />
      ) : (
        renderCardView()
      )}

      {hasOrderChanged && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            position: 'fixed',
            top: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<Save />}
            onClick={handleSaveOrder}
          >
            Save Order
          </Button>
          <Button
            variant="contained"
            startIcon={<Undo />}
            color="inherit"
            onClick={handleResetOrder}
          >
            Reset Order
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default CdsDataGrid;