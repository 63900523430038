import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Chip, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, Typography, } from '@mui/material';
import Popover from '@mui/material/Popover';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Insight } from '../../services/api';
import { useInsight } from './context';
import InsightChart from './InsightChart';
import InsightDetail from './InsightDetail';

interface InsightCardsProps {
  insights: Insight[];
  loading: boolean;
}

const FilterPopover: React.FC<{
  factors: string[];
  metrics: string[];
  selectedFactor: string;
  selectedMetric: string;
  setSelectedFactor: (factor: string) => void;
  setSelectedMetric: (metric: string) => void;
}> = ({ factors, metrics, selectedFactor, selectedMetric, setSelectedFactor, setSelectedMetric }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <>
      <IconButton onClick={handleClick} aria-describedby={id}>
        <FilterListIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2, minWidth: 200 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Factor</InputLabel>
            <Select
              value={selectedFactor}
              label="Factor"
              onChange={(e) => setSelectedFactor(e.target.value as string)}
            >
              <MenuItem value="">All Factors</MenuItem>
              {factors.map((factor) => (
                <MenuItem key={factor} value={factor}>{factor}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Metric</InputLabel>
            <Select
              value={selectedMetric}
              label="Metric"
              onChange={(e) => setSelectedMetric(e.target.value as string)}
            >
              <MenuItem value="">All Metrics</MenuItem>
              {metrics.map((metric) => (
                <MenuItem key={metric} value={metric}>{metric}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
};

const InsightCards: React.FC<InsightCardsProps> = ({ insights, loading }) => {
  const { api } = useAuthenticatedUser();
  const { filters } = useInsight();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<Set<string>>(new Set());
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const [selectedFactor, setSelectedFactor] = useState<string>('');
  const [selectedMetric, setSelectedMetric] = useState<string>('');
  const [isBookmarking, setIsBookmarking] = useState<boolean>(false);

  const factors = useMemo(() => {
    const factorSet = new Set<string>();
    insights.forEach(insight => {
      if (insight.factor && insight.factor.title) {
        factorSet.add(insight.factor.title);
      }
    });
    return Array.from(factorSet);
  }, [insights]);

  const metrics = useMemo(() => {
    const metricSet = new Set<string>();
    insights.forEach(insight => {
      if (insight.metric) {
        metricSet.add(insight.metric);
      }
    });
    return Array.from(metricSet);
  }, [insights]);




  const filteredInsights = useMemo(() => {
    return insights.filter(insight => {
      const factorMatch = selectedFactor ? insight.factor?.title?.includes(selectedFactor) : true;
      const metricMatch = selectedMetric ? insight.metric?.includes(selectedMetric) : true;
      return factorMatch && metricMatch;
    });
  }, [insights, selectedFactor, selectedMetric]);

  const validInsights = filteredInsights.filter(insight => {
    const [factorValue] = insight.content.match(/\d+(\.\d+)?/) || [];
    return factorValue && parseFloat(factorValue) > 0;
  });

  const positiveInsights = validInsights.filter(i => i.isPositive);
  const negativeInsights = validInsights.filter(i => !i.isPositive);

  useEffect(() => {
    const fetchBookmarkedInsights = async () => {
      try {
        const bookmarkedInsights = await api.insights.getBookmarkedInsights();
        setFavorites(new Set(bookmarkedInsights.map(insight => insight.id)));
      } catch (error) {
        console.error('Error fetching bookmarked insights:', error);
      }
    };
    fetchBookmarkedInsights();
  }, [api]);

  const toggleFavorite = async (insight: Insight) => {
    setIsBookmarking(true);
    try {
      const updatedInsight = await api.insights.bookmarkInsight(insight.id, insight);
      
      setFavorites(prev => {
        const newFavorites = new Set(prev);
        if (updatedInsight.isBookmarked) {
          newFavorites.add(updatedInsight.id);
        } else {
          newFavorites.delete(updatedInsight.id);
        }
        return newFavorites;
      });
    } catch (error) {
      console.error('Error bookmarking insight:', error);
    } finally {
      setIsBookmarking(false);
    }
  };

  const handleInsightClick = (insight: Insight) => {
    setSelectedInsight(insight);
  };


  const renderInsightGroup = (groupInsights: Insight[]) => (
    <div className='card-container'>
      {(showAll ? groupInsights : groupInsights.slice(0, 6)).map((insight) => (
        <div className='insight-card' key={insight.id} onClick={() => handleInsightClick(insight)}>
          <img 
            src={insight.isPositive ? '/assets/img/positive-insight.svg' : '/assets/img/negative-insight.svg'} 
            alt={insight.isPositive ? "Positive insight" : "Negative insight"} 
          />
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <div dangerouslySetInnerHTML={{ __html:insight.content}}/>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                  {insight.tags.map((tag: string, tagIndex: number) => (
                    <Grid item key={tagIndex}>
                      <Chip label={tag} size="small" />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <IconButton 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  toggleFavorite(insight); 
                }} 
                color="primary"
                disabled={isBookmarking}
              >
                {favorites.has(insight.id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );

  const renderFilters = () => (
    <FilterPopover
      factors={factors}
      metrics={metrics}
      selectedFactor={selectedFactor}
      selectedMetric={selectedMetric}
      setSelectedFactor={setSelectedFactor}
      setSelectedMetric={setSelectedMetric}
    />
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {insights.length === 0 ? (
        <Box textAlign="center">
          <Typography variant='body1' color={'text.secondary'} gutterBottom>Use the filter and click on "Analyze" to calculate the insights</Typography>
        </Box>
      ) : (
        <>
 
          {selectedInsight && (
            <Grid container spacing={2} >
              <Grid item xs={12} md={6}>
                <InsightDetail 
                  insight={selectedInsight} 
                  onToggleFavorite={() => toggleFavorite(selectedInsight)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InsightChart 
                  insightId={selectedInsight.id} 
                  filters={filters}

                />
              </Grid>
            </Grid>
          )}
          <Box display="flex" justifyContent="flex-end" mb={2}>
            {renderFilters()}
          </Box>
          <Tabs value={activeTab} onChange={(_, newValue: number) => setActiveTab(newValue)} centered>
            <Tab label="Positive Insights" />
            <Tab label="Negative Insights" />
          </Tabs>
          <Box sx={{ mt: 2 }}>
            {activeTab === 0 ? renderInsightGroup(positiveInsights) : renderInsightGroup(negativeInsights)}
          </Box>
          {(activeTab === 0 ? positiveInsights : negativeInsights).length > 6 && (
            <Button onClick={() => setShowAll(!showAll)} sx={{ mt: 2 }}>
              {showAll ? "Show Less" : "Show All"}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default InsightCards;