import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useAuthenticatedUser } from '../../contexts/auth';
import { ChartData } from '../../services/api';

interface InsightChartProps {
  insightId: string;
  filters: any;
  maxDataPoints?: number;
}

const InsightChart: React.FC<InsightChartProps> = ({ insightId, filters, maxDataPoints = 60 }) => {
  const { api } = useAuthenticatedUser();
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChartData = async () => {
      setLoading(true);
      try {
        const data = await api.insights.getChartData(insightId, filters);
        setChartData(data);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchChartData();
  }, [api, insightId, filters]);

  const processedData = useMemo(() => {
    if (!chartData || chartData.data.length <= maxDataPoints) {
      return chartData?.data;
    }

    const step = Math.ceil(chartData.data.length / maxDataPoints);
    return chartData.data.filter((_, index) => index % step === 0);
  }, [chartData, maxDataPoints]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <CircularProgress />
      </Box>
    );
  }

  if (!chartData || !processedData) {
    return <Typography>No chart data available.</Typography>;
  }

  const formatYAxis = (value: number) => {
    switch (chartData.metric) {
      case 'CTR':
      case 'ConversionRate':
      case 'RetentionRate':
        return `${value.toFixed(2)}%`;
      case 'views':
        return value.toLocaleString();
      case 'WatchTime':
        return `${value.toFixed(1)}s`;
      default:
        return value.toString();
    }
  };

  const getXAxisDomain = () => {
    if (chartData.factorType === 'number') {
      const minValue = Math.round(Math.min(...processedData.map(item => item.factorValue)));
      const maxValue = Math.round(Math.max(...processedData.map(item => item.factorValue)));
      return [minValue, maxValue];
    }
    return ['dataMin', 'dataMax'];
  };

  const renderChart = () => {
    const CommonProps = {
      data: processedData,
      margin: { top: 5, right: 30, left: 20, bottom: 5 }
    };

    const CommonAxisProps = {
      axisLine: false,
      tickLine: false,
      tick: { fill: '#888', fontSize: 12 }
    };

    if (chartData.factorType === 'category') {
      return (
        <BarChart {...CommonProps}>
          <XAxis dataKey="factorValue" {...CommonAxisProps} />
          <YAxis {...CommonAxisProps} domain={['auto', 'auto']} tickFormatter={formatYAxis} />
          <Tooltip
            formatter={(value: number) => formatYAxis(value)}
            labelFormatter={(label: string) => `${chartData.factorTitle}: ${label}`}
          />
          <Bar dataKey="performance" name={chartData.metric}>
            {processedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill="#8884d8"
                radius={10}
              />
            ))}
          </Bar>
          {chartData.insightRanges && chartData.insightRanges.map((range, index) => (
            <ReferenceArea
              key={`range-${index}`}
              x1={range.min}
              x2={range.max}
              strokeOpacity={0}
              fill={range.performanceDiff > 0 ? "#06d6a0" : "#ff8884"}
              fillOpacity={0.2}
            />
          ))}
        </BarChart>
      );
    } else {
      return (
        <LineChart {...CommonProps}>
          <XAxis
            dataKey="factorValue"
            type={chartData.factorType}
            {...CommonAxisProps}
            domain={getXAxisDomain()}
          />
          <YAxis
            type="number"
            {...CommonAxisProps}
            domain={['auto', 'auto']}
            tickFormatter={formatYAxis}
          />
          <Tooltip
            formatter={(value: number) => formatYAxis(value)}
            labelFormatter={(label: number) => `${chartData.factorTitle}: ${label}`}
          />
          {chartData.insightRanges && chartData.insightRanges.map((range, index) => (
            <ReferenceArea
              key={`range-${index}`}
              x1={range.min}
              x2={range.max}
              strokeOpacity={0.3}
              fill={range.performanceDiff > 0 ? "#8884d8" : "#ff8884"}
              fillOpacity={0.5}
            />
          ))}
          <Line
            type="monotone"
            dataKey="performance"
            stroke="#8884d8"
            strokeWidth={2}
            dot={{ r: 4, fill: '#8884d8' }}
            name={chartData.metric}
          />
          {chartData.insightRanges && chartData.insightRanges.map((range, index) => (
            <React.Fragment key={`reference-lines-${index}`}>
              <ReferenceLine x={range.min} />
              <ReferenceLine x={range.max} />
            </React.Fragment>
          ))}
        </LineChart>
      );
    }
  };

  return (
    <Box className="chart-container">
      <ResponsiveContainer width="100%" height={250}>
        {renderChart()}
      </ResponsiveContainer>
    </Box>
  );
};

export default InsightChart;