import { AddCircleOutline, Calculate, Psychology } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { typeExamples } from '../../models/creativeDataStructure/uiTypes';
import { IVirtualColumn, defaultVC } from '../../models/virtualColumn';
import Formula from '../General/FormulaInput';
import TreeView from '../General/TreeView';
import VirtualFieldsTable from './VirtualFieldsTable';
import { useCalculatedFields } from './context';
import { JsonValue } from './types';
import { generateNameFromTitle } from './utils';


const FieldBuilder: React.FC = () => {
  const { api } = useAuthenticatedUser();
  const { data, refreshData } = useCalculatedFields();
  const [virtualFields, setVirtualFields] = useState<IVirtualColumn[]>([]);
  const [newField, setNewField] = useState<IVirtualColumn>(defaultVC());
  const [editingField, setEditingField] = useState<IVirtualColumn | null>(null);
  const [error, setError] = useState<string>('');
  const [evaluationResult, setEvaluationResult] = useState<JsonValue | null>(null);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [evaluatingField, setEvaluatingField] = useState<string | null>(null);
  const handleSampleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentSampleIndex(value - 1);
  };

  const fetchVirtualFields = useCallback(() => {
    api.vc
      .all()
      .then(setVirtualFields)
      .catch(err => {
        console.error('Error fetching virtual fields:', err);
        setError('Failed to fetch virtual fields.');
      });
  }, [api]);

  useEffect(() => {
    if(!data) return;
    fetchVirtualFields();
  }, [data, fetchVirtualFields]);


  const handleAddOrUpdateField = async () => {
    const fieldToSave = editingField || newField;
    if (!fieldToSave.title || !fieldToSave.formula) {
      setError('Please provide a title and formula.');
      return;
    }

    const result = await api.vc.ValidateFormula(fieldToSave.formula,0);
    if (result !== null) {
      const name = generateNameFromTitle(fieldToSave.title);

      const updatedField = {
        ...fieldToSave,
        name,
      };

      try {
        if (editingField) {
          await api.vc.update(updatedField);
        } else {
          await api.vc.create(updatedField);
        }

        await refreshData();
        await fetchVirtualFields();

        setNewField(defaultVC());
        setEditingField(null);
        setEvaluationResult(null);
        setError('');
      } catch (err) {
        setError(`Failed to ${editingField ? 'update' : 'add'} the virtual field. Please try again.`);
        console.error(`Error ${editingField ? 'updating' : 'adding'} virtual field:`, err);
      }
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (editingField) {
      setEditingField(prev => {
        if (prev) {
          return { ...prev, [name]: value };
        }
        return prev;
      });
    } else {
      setNewField(prev => ({ ...prev, [name]: value }));
    }

    if (name === 'formula') {
      setEvaluationResult(null);
    }
  };

  const handleFormulaChange = (code: string) => {
    if (editingField) {
      setEditingField(prev => {
        if (prev) {
          return { ...prev, formula: code };
        }
        return prev;
      });
    } else {
      setNewField(prev => ({ ...prev, formula: code }));
    }
    setEvaluationResult(null);
  };

   const handleEvaluate = async () => {
    const fieldToEvaluate = editingField || newField;
    try {
      setEvaluationResult('Evaluating...');
      const result = await api.vc.ValidateFormula(fieldToEvaluate.formula || '',currentSampleIndex);
  
      
      setEvaluationResult(result);
      setError('');

    } catch (err) {
      setError('Invalid MongoDB expression format. Please ensure it\'s a valid JSON object.');
      setEvaluationResult(null);
    }
  };

  const handleGenerateFormula = async () => {
    const fieldToGenerate = editingField || newField;
   
    if (!fieldToGenerate.description) {
      setError('Please provide  and description for AI assistance');
      return;
    }

    try {
      setIsLoading(true);
      const formulaDescription = fieldToGenerate.description
      const expectedOutput = typeExamples[fieldToGenerate.type || "string"]!;
      const generatedFormula = await api.vc.genFormula({ 
        formulaDescription,
        expectedOutput 
      });

      if (editingField) {
        setEditingField(prev => prev ? { ...prev, formula: generatedFormula } : null);
      } else {
        setNewField(prev => ({ ...prev, formula: generatedFormula }));
      }
      setError('');
    } catch (err) {
      setError('Failed to generate formula using AI. Please try again or write the formula manually.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (field: IVirtualColumn) => {
    setEditingField(field);
    setNewField(defaultVC());
  };

  const handleDelete = async (field: IVirtualColumn) => {
    try {
      await api.vc.delete(field._id!);
      await refreshData();
      await fetchVirtualFields();
    } catch (error) {
      console.error('Error deleting virtual field:', error);
      setError('Failed to delete the virtual field.');
    }
  };


  const handleEvaluateOne = async (field: IVirtualColumn, onProgress: (progress: number) => void) => {
    if (!field._id) {
      setError('Field ID is missing.');
      return;
    }

    try {
      await api.vc.Eval(onProgress, field._id);
      await refreshData();
    } catch (error) {
      console.error('Error evaluating virtual field:', error);
      setError('Failed to evaluate the virtual field.');
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>Manage Virtual Fields</Typography>
      
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '50px'
      }}>
        <Grid>

          <TreeView data={data[currentSampleIndex]} onSelect={(path, value) => {}} />
          <Pagination 

                count={data.length} 
                page={currentSampleIndex + 1} 
                onChange={handleSampleChange} 
                color="primary" 
                style={{ margin:"20px auto", width:"100%" }}
              />
        </Grid>
       
        <Paper style={{ padding: '1rem', boxShadow:'0 0 30px 0 #0000001a', flexGrow:2, display:'flex', flexDirection:'column', height:'100%' }}>
          <Typography variant="h6" gutterBottom>{editingField ? 'Edit' : 'Add New'} Virtual Field</Typography>

          <TextField
            fullWidth
            name="title"
            label="Field Title"
            value={editingField ? editingField.title : newField.title}
            onChange={handleFieldChange}
            margin="normal"
          />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                name="description"
                label="Field Description"
                value={editingField ? editingField.description : newField.description || ''}
                onChange={handleFieldChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                name="type"
                label="Field Type"
                value={editingField ? editingField.type : newField.type || 'text'}
                onChange={handleFieldChange}
                margin="normal"
                SelectProps={{
                  native: true,
                }}
              >
                {Object.entries(typeExamples).map(([key, value]) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Formula
            value={editingField ? editingField.formula : newField.formula || ''}
            onChange={handleFormulaChange}
            suggestions={suggestions}
          />
          
          <Box mt={2} display="flex" gap={2}>
            <Button
              variant="outlined"
              onClick={handleEvaluate}
              style={{
                color: 'black',
                backgroundColor: 'white',
                borderColor: 'black',
                boxShadow: 'none',
              }}
              startIcon={<Calculate />}
            >
              Evaluate Formula
            </Button>
            <Button
              variant="outlined"
              onClick={handleGenerateFormula}
              disabled={isLoading}
              style={{
                color: 'black',
                backgroundColor: 'white',
                borderColor: 'black',
                boxShadow: 'none',
              }}
              startIcon={isLoading ? <CircularProgress size={20} /> : <Psychology />}
            >
              AI Assist
            </Button>
          </Box>

          {evaluationResult !== null && (
            <Box mt={2}>
              <Typography variant="subtitle1">Evaluation Result:</Typography>
              <Paper elevation={2} style={{ color:'#ffffff', backgroundColor: '#1e1e1e', padding: '1rem', maxHeight: '200px', overflow: 'auto' }}>
                <pre
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(evaluationResult, null, 2)
                  }}
                />
              </Paper>
            </Box>
          )}

          <Button
            variant="contained"
            onClick={handleAddOrUpdateField}
            style={{
              marginTop: '3rem',
              backgroundColor: 'black',
              color: 'white',
              boxShadow: 'none',
            }}
            startIcon={<AddCircleOutline />}
          >
            {editingField ? 'Update' : 'Add'} Virtual Field
          </Button>
        </Paper>
      </div>

      {error && (
        <Alert severity="error" style={{ marginTop: '1rem' }}>
          {error}
        </Alert>
      )}
      
      <VirtualFieldsTable
        virtualFields={virtualFields}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </>
  );
};

export default FieldBuilder;
