import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import CreativeDataCards from '../../components/CreativeDataCards';
import PerformanceCard from '../../components/PerformanceCard';
import CreativesFilters from './Filters';
import GlobalGuidelinesScore from './GlobalGuidelinesScores';
import useDashboard, { DashboardProvider } from './context';

const Dashboard: React.FC = () => {
  return (
    <DashboardProvider>
      <DashboardInner />
    </DashboardProvider>
  );
};

const DashboardInner: React.FC = () => {
  let { selectedCd, results } = useDashboard();
  selectedCd = selectedCd?.filter(cds => (cds.showInDashboard));
  const measures = results.Measures;

  if (results.VideosCount === 0) {
    return (
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%'
        }}
      >
        <img width="64" src="/loading.gif" alt="Loading..." />
      </Box>
    );
  } else {
    return (
      <Box>
        <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, backgroundColor: '#fff' }}>
          <CreativesFilters />
        </Box>
        <Stack height='100%' gap={2} sx={{ mt: 2 }}>
          <Stack direction="row" gap={2}>
            <PerformanceCard title="Impressions" iconName="Impressions" value={measures?.Impressions.value} unit="" data={measures?.Impressions.history} />
            <PerformanceCard title="Watch Time" iconName="Watch Time" value={measures?.WatchTime.value} unit="hrs" data={measures?.WatchTime.history} />
            <PerformanceCard title="Conversion" iconName="Conversion" value={measures?.ConversionRate.value} unit="%" data={measures?.ConversionRate.history} />
            <PerformanceCard title="Spend" iconName="Spend" value={measures?.Spend.value} unit="$" data={measures?.Spend.history} />
          </Stack>
          <Box width="100%">
            <GlobalGuidelinesScore />
          </Box>
          <Box>
            <Box py={2}>
              <Grid container spacing={2}>
                {selectedCd && <CreativeDataCards selectedCd={selectedCd} creativeData={results.CreativeData} />}
              </Grid>
            </Box>
          </Box>
        </Stack>
      </Box>
    );
  }
};

export default Dashboard;