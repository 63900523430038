import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import DateRangePicker from '../../components/DateRangePicker';
import NormalizedSelect from '../../components/NormalizedSelect';
import { sources } from '../../models';
import { StyledFiltersBar } from '../../theme/bars';
import { useInsight } from './context';


const InsightFilters: React.FC = () => {
  const { filters, setFilter, availableFilters, calculateInsights } = useInsight();
  const { platform, brand, country, start, end } = filters;
  const { platforms, brands, countries } = availableFilters;
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [customDateRange, setCustomDateRange] = useState(false);

  const handleDateRangeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDateRangeClose = () => {
    setAnchorEl(null);
  };

  const setDateRange = (range: string) => {
    const today = new Date();
    let startDate = new Date();
    let endDate = today;

    switch (range) {
      case '3months':
        startDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
        break;
      case '6months':
        startDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
        break;
      case 'ytd':
        startDate = new Date(today.getFullYear(), 0, 1);
        break;
      case 'custom':
        setCustomDateRange(true);
        handleDateRangeClose();
        return;
      default:
        break;
    }

    setFilter('start')(startDate);
    setFilter('end')(endDate);
    setCustomDateRange(false);
    handleDateRangeClose();
  };

  const handleAnalyze = () => {
    calculateInsights();
  };

  return (

    <StyledFiltersBar direction='row' spacing={5} justifyContent='space-between'>
      <Box display='flex' gap={10} flexDirection='row' alignItems='center'>
      <NormalizedSelect
        label='Platform'
        value={platform}
        onChange={setFilter('platform')}
        options={platforms}
      />
      <NormalizedSelect
        label='Brand'
        value={brand}
        onChange={setFilter('brand')}
        options={brands}
      />
      <NormalizedSelect
        label='Country'
        value={country}
        onChange={setFilter('country')}
        options={countries}
      />
      <NormalizedSelect
        label='Source'
        value={filters.source}
        onChange={setFilter('source')}
        options={sources}
      />
      <Tooltip title="Date Range">
        <IconButton size="small" onClick={handleDateRangeClick}>
          <DateRangeIcon fontSize="small" />
          <ArrowDropDownIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDateRangeClose}
      >
        <MenuItem onClick={() => setDateRange('3months')}>Last 3 Months</MenuItem>
        <MenuItem onClick={() => setDateRange('6months')}>Last 6 Months</MenuItem>
        <MenuItem onClick={() => setDateRange('ytd')}>Year to Date</MenuItem>
        <MenuItem onClick={() => setDateRange('custom')}>Custom Range</MenuItem>
      </Menu>
      {customDateRange && (
        <DateRangePicker
          startDate={start}
          endDate={end}
          setStartDate={setFilter('start')}
          setEndDate={setFilter('end')}
        />
      )}
</Box>
      <Button
        variant="contained"
        style={{ backgroundColor: '#000',width: '200px', color: '#fff',borderRadius: '20px',height: '40px'}}
        startIcon={<AnalyticsIcon />}
        onClick={handleAnalyze}
      >
        Analyze
      </Button>
    </StyledFiltersBar>);


};

export default InsightFilters;
