import { TimeRange } from '../models/creativeData';

function parseAllDates<T>(data: T): T {
  Object.entries(data as any).forEach(([key, value]) => {
    if (value instanceof Object) parseAllDates(value);
    else if (value instanceof Array) value.forEach(parseAllDates);
    else if (key === 'date') (data as any)[key] = new Date(value as string);
  });
  return data;
}
function parseTimestamp(timestamp: string): number {
  // Convert the string to a number
  const timestampMs = parseInt(timestamp);

  // Convert milliseconds to seconds
  return timestampMs / 1000;
}

function parseDuration(instance: TimeRange): number {
  return parseTimestamp(instance.end) - parseTimestamp(instance.start);
}

function formatDurationHumanly(durationSeconds: number): string {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = durationSeconds % 60;
  return [hours, minutes, seconds]
    .map((value, index) => formatUnitHumanly(value, ['h', 'm', 's'][index]))
    .join(' ');
}

function formatUnitHumanly(value: number, unit: string): string {
  if (!value) return '';
  if (value < 10) return `0${value}${unit}`;
  return `${value}${unit}`;
}

const dates = {
  parseAllDates,
  parseTimestamp,
  parseDuration,
  formatDurationHumanly,
};
export default dates;
