import React from 'react';
import { Typography, Box, Chip, IconButton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Insight } from '../../services/api';

interface InsightDetailProps {
  insight: Insight;
  onToggleFavorite: () => void;
}

const InsightDetail: React.FC<InsightDetailProps> = ({ insight, onToggleFavorite }) => {
  return (
    <div className='insight-details-panel'>
      <div className='insight-details-left'>
          <Box  display="flex" gap={2} alignItems="center">
          <img 
            src={insight.isPositive ? '/assets/img/positive-insight.svg' : '/assets/img/negative-insight.svg'} 
            alt={insight.isPositive ? "Positive insight" : "Negative insight"} 
          />
          <div>
          <Typography variant="body1" fontSize={18} gap={2} paragraph>
            {insight.content}
            </Typography>
            <Typography fontSize={12} color="text.secondary">
              {`From ${formatDate(insight.dateRange.start)} to ${formatDate(insight.dateRange.end)}`} 
            </Typography>
            <Typography fontSize={12} color="text.secondary">
              {`${insight.numberOfVideos} videos analyzed`} 
            </Typography>
            </div>
          </Box>
    
     
     
      <Box >
      <Typography fontSize={10}  gutterBottom color="text.secondary">
      Calculated for
      </Typography>
      <Box display="flex" justifyContent="left" gap={1}>
        <Chip label={insight.country || 'All Countries'} />
        <Chip label={insight.platform || 'All Platforms'} />
        <Chip label={insight.brand || 'All Brands'} />
        
      </Box>
      </Box>

        </div>
        <div className='insight-details-right'>
        <Box>
        <Typography variant="subtitle1" gutterBottom>
          {insight.factor?.category} &gt; {insight.factor?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {insight.factor?.description}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" gutterBottom>
          {insight.metric}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {getMetricDescription(insight.metric)}
        </Typography>
      </Box>

        </div>
        <div className='insight-details-action'>
        <IconButton onClick={onToggleFavorite}>
          {insight.isBookmarked ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
        </IconButton>
        </div>
        </div>


  



     
  );
};

// Helper function to get metric description
function getMetricDescription(metric: string): string {
  switch (metric) {
    case 'WatchTime':
      return 'The total amount of time users spend watching a video. It measures engagement and content quality.';
    case 'RetentionRate':
      return 'The percentage of viewers who continue watching a video over time. It indicates how well the content maintains audience interest.';
    case 'ConversionRate':
      return 'The percentage of users who take a desired action (e.g., make a purchase, sign up) after viewing the content.';
    case 'views':
      return 'The total number of times a video has been watched. It represents the reach and popularity of the content.';
    case 'CTR':
      return 'Click-Through Rate is the percentage of people who click on a specific link or call to action out of the total number who view a page, email, or advertisement.';
    default:
      return 'Description not available.';
  }
}

// Helper function to format date
function formatDate(date?: Date): string {
  return date ? date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }) : 'N/A';
}

export default InsightDetail;