interface LoadingRessProps {
  resName?: string;
}
const LoadingRessourcePage: React.FC<LoadingRessProps> = props => {
  const { resName = 'ressource' } = props;
  return (
    <div>
      <h1>Loading {resName}...</h1>
    </div>
  );
};
export default LoadingRessourcePage;
