export interface User {
  UserID: number;
  Email: string;
  Username: string;
  IsActive: boolean;
  BrandID: number;
}

export type WithPass<T> = T & { Password: string };
export type WithRoles<T> = T & { Roles: Role[] };
export type WithPerms<T> = T & { Permissions: Permission[] };

export interface Role {
  RoleID: number;
  RoleName: RoleName | string;
  RoleDescription: string | null;
}

export type RoleName = 'ADMIN' | 'USER';

export interface Permission {
  PermissionID: number;
  PermissionName: string;
  PermissionDescription: string | null;
}

export const userHasRole = <R extends RoleName>(
  user: WithRoles<User>,
  role: R
) => user.Roles.some(r => r.RoleName === role);
