import React from 'react';
import { Box, Stack } from '@mui/material';
import InsightSpotterFilters from './Filters';
import InsightCards from './InsightsCards';
import { InsightProvider, useInsight } from './context';
import InsightDisclaimer from './InsightDisclaimer';

const InsightSpotter: React.FC = () => {
  return (
    <InsightProvider>
      <InsightSpotterContent />
    </InsightProvider>
  );
};

const InsightSpotterContent: React.FC = () => {

  const { insights, loading } = useInsight();
  return (
    <Stack height="100%">
      <InsightDisclaimer/>
      <InsightSpotterFilters />
      <Box flex={1} overflow="auto">
        <InsightCards 
        insights={insights}
        loading={loading}
        />
      </Box>
    </Stack>
  );
};

export default InsightSpotter;
