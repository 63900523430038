import { Platform } from '.';
import { Optional } from '../utils/typing';
import {
  ActualType,
  ArrayType,
  ICreativeDataStructure,
  isSType,
  IStructure,
  SimpleType,
  UiType,
} from './creativeDataStructure';

export interface IGuideline {
  _id: string;
  categories: ICategory[];
}

export type ICategory = {
  name: string;
  rules: IRule[];
  weight: number;
  icon?: string;
};

export interface IRule<
  T extends OperationType = OperationType,
  ON extends OperationName<T> = OperationName<T>
> {
  cds: ICreativeDataStructure<IStructure<UiType<T>>>;
  operation: IOperation<T, ON>;
  weight: number;
}

export interface IBrandGuideline extends IGuideline {
  brand: string;
}

export interface IPlatformGuideline extends IGuideline {
  platform: Platform;
}

export const operations = {
  string: {
    eq: ['target'],
    contains: ['target'],
    startsWith: ['target'],
    endsWith: ['target'],
    regex: ['target'],
  },
  number: {
    lt: ['target'],
    lte: ['target'],
    gt: ['target'],
    gte: ['target'],
    eq: ['target'],
    ne: ['target'],
    btw: ['min', 'max'],
  },
  boolean: {
    eq: ['target'],
    ne: ['target'],
  },
  date: {
    lt: ['target'],
    lte: ['target'],
    gt: ['target'],
    gte: ['target'],
    eq: ['target'],
    ne: ['target'],
    btw: ['min', 'max'],
  },
  array: {
    includes: ['target'],
    excludes: ['target'],
    empty: [],
    notEmpty: [],
    length: ['target'],
    minLength: ['target'],
    maxLength: ['target'],
  },
} as const;

type OperationsSchema = typeof operations;
export type OperationType = SimpleType | ArrayType;
export type OperationName<T extends OperationType = OperationType> =
  T extends any ? keyof OperationsSchema[T] : never;

type OperationParamName<
  T extends OperationType,
  ON extends OperationName<T>
> = Extract<OperationsSchema[T][ON], ReadonlyArray<string>>[number];

export type RuleOperationParams<
  T extends OperationType = OperationType,
  ON extends OperationName<T> = OperationName<T>
> = {
  [OPN in OperationParamName<T, ON>]: ParamType<T, ON>;
};

type ParamType<
  T extends OperationType,
  ON extends OperationName<T>
> = T extends 'number' | 'boolean' | 'date'
  ? ActualType<T>
  : ON extends 'length' | 'minLength' | 'maxLength'
  ? number
  : string;

export type IOperation<
  T extends OperationType = OperationType,
  ON extends OperationName<T> = OperationName<T>
> = {
  name: ON;
  params: RuleOperationParams<T, ON>;
};

export function getAvailableOperations<T extends OperationType>(
  type: T
): OperationName<T>[] {
  return Object.keys(operations[type]) as OperationName<T>[];
}

export function buildOperation<
  T extends OperationType,
  ON extends OperationName<T>
>(type: T, name: ON): IOperation<T, ON> {
  const operationsForType = operations[type as keyof typeof operations];
  const paramsKeys = operationsForType[
    name as keyof typeof operationsForType
  ] as readonly string[];
  const params = Object.fromEntries(
    paramsKeys.map(key => [key, defaultValue(type)])
  ) as RuleOperationParams<T, ON>;
  return { name, params };
}

function defaultValue(type: OperationType): ActualType<OperationType> {
  switch (type) {
    case 'string':
      return '';
    case 'number':
      return 0;
    case 'boolean':
      return false;
    case 'date':
      return new Date();
    case 'array':
      return [];
  }
}

export interface IGuidelineScore {
  totalScore: number;
  scores: {
    name: string;
    icon?: string;
    score: number;
  }[];
}

export type IScoreHistory = {
  [key: string]: number[];
};

export function validateGuideline(
  guideline: Optional<IGuideline, '_id'>
): guideline is Optional<IGuideline, '_id'> {
  const accWeight = guideline.categories.reduce((acc, category) => {
    return acc + category.weight;
  }, 0);
  if (accWeight !== 1) return false;
  if (!guideline.categories.every(validateCategory)) return false;
  return true;
}

export function validateCategory(category: ICategory): category is ICategory {
  if (!category.name || !category.rules || !category.weight) return false;
  const accWeight = category.rules.reduce((acc, rule) => {
    return acc + rule.weight;
  }, 0);
  if (accWeight !== 1) return false;
  if (!category.rules.every(validateRule)) return false;
  return true;
}

export function validateRule<T extends SimpleType | ArrayType>(
  rule: IRule<T>
): rule is IRule<T> {
  const { cds, operation, weight } = rule;
  if (!cds || !operation || typeof weight !== 'number') return false;
  if (!cds.structure || !operation.name || !operation.params) return false;

  if (isSType(cds.structure, 'object')) {
    return false;
  }

  return true;
}
