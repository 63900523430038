import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  Brand,
  CreatePreflightVideo,
  IAnalyzedVideo,
  IPreflightVideo,
  IVideo,
  IVideoSummary,
  Platform,
  Source,
} from '../models';
import { ICreativeDataStructure } from '../models/creativeDataStructure';
import { SocialMediaInfo } from '../models/experiments';
import {
  IBrandGuideline,
  IGuidelineScore,
  IPlatformGuideline,
  IScoreHistory,
} from '../models/guidelines';
import { IMomentResult } from '../models/moments';
import { OperaEndpoint } from '../models/opera';
import { PaginatedResult, PaginationOptions } from '../models/pagination';
import { Role, User, WithPass, WithPerms, WithRoles } from '../models/users';
import { IVirtualColumn } from '../models/virtualColumn';
import dates from '../utils/dates';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const unauthenticatedInstance = axios.create({
  baseURL,
});

interface VideoFilters {
  platform: Platform;
  brand: string;
  country: string;
  campaign: string;
  source: Source;
  analyzed: boolean;
  start: Date;
  end: Date;
}

interface EvalAllProgressCallback {
  (progress: number): void;
}

interface EvalAllResult {
  success: boolean;
  error?: string;
}

export interface SearchResult {
  id: string;
  content?: string;
  description?: string;
  tags?: { key: string; value: number }[];
  text?: { id: number; content: string }[];
  speech?: { id: number; content: string }[];
  highlights?: Record<string, string[]>;
}

export default function buildApi(
  accessToken: string,
  options: { on403?: () => Promise<void> } = {}
) {
  const { on403 } = options;
  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  instance.interceptors.response.use(
    res => res,
    error => {
      if (error.response?.status === 403 && on403) {
        on403();
        return new Promise(() => {});
      } else return Promise.reject(error);
    }
  );

  // Inside your buildApi function, add this new section:
  const aiSearch = {
    search: (query: string, options?: any) =>
      instance
        .post<any[]>('/aiSearch', { query, options })
        .then(res => res.data),
  };

  const imageAnalysis = {
    analyze: <T extends OperaEndpoint>(file: File, analysisType: T) => {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('source', 'Opera');
  
      const endpoint = analysisType === 'caption' ? 'describe' : analysisType;
  
      return instance.post(`/opera/${endpoint}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then(res => {
        if (analysisType === 'caption') {
          // Returning the entire response data for 'caption' analysis
          return res.data ;
        }
        return res.data;
      });
    },
    
    analyzeAll: (file: File) => {
      return Promise.all([
        imageAnalysis.analyze(file, 'face'),
        imageAnalysis.analyze(file, 'caption'),
        imageAnalysis.analyze(file, 'classify'),
      ]).then(([face, caption, classify]) => {
        const inferenceTime = Math.max(
          face.inferenceTime,
          caption.inferenceTime,
          classify.inferenceTime
        );
        return { face, caption, classify, inferenceTime };
      });
  }}
  ;

  const videos = {
    all: (params?: Partial<VideoFilters & PaginationOptions>) =>
      instance
        .get('/videos', { params })
        .then(res => res.data as PaginatedResult<IVideo>)
        .then(({ docs, ...rest }) => ({
          ...rest,
          docs: docs.map(dates.parseAllDates),
        })),
    summary: (params?: Partial<VideoFilters>) =>
      instance
        .get<IVideoSummary>('/videos/summary', { params })
        .then(res => res.data),
    one: (id: string) =>
      instance
        .get<IAnalyzedVideo>(`/videos/${id}`)
        .then(res => res.data)
        .then(dates.parseAllDates),
    latest: () =>
      instance
        .get<IAnalyzedVideo>(`/latest`)
        .then(res => res.data)
        .then(dates.parseAllDates),
    platforms: buildStaticFetcher<'platform'>('/videos/platforms'),
    brands: buildStaticFetcher<'brand'>('/videos/brands'),
    countries: buildStaticFetcher<'country'>('/videos/countries'),
    campaigns: buildStaticFetcher<'campaign'>('/videos/campaigns'),
    scores: {
      brand: (id: string) =>
        instance
          .get<IGuidelineScore>(`/videos/${id}/scores/brand`)
          .then(res => res.data),
      platform: (id: string) =>
        instance
          .get<IGuidelineScore>(`/videos/${id}/scores/platform`)
          .then(res => res.data),
    },
  };

  const vc = {
    all: () => instance.get<IVirtualColumn[]>('/vc').then(res => res.data),
    EvalAll: (onProgress: EvalAllProgressCallback): Promise<EvalAllResult> => {
      return new Promise((resolve, reject) => {
        const eventSource = new EventSourcePolyfill(`${baseURL}/vc/EvalAll`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });
    
        eventSource.onmessage = event => {
          const data = JSON.parse(event.data);
          if (data.progress) {
            onProgress(data.progress);
          } else if (data.complete) {
            eventSource.close();
            resolve({ success: true });
          } else if (data.error) {
            eventSource.close();
            reject(new Error(data.error));
          }
        };
    
        eventSource.onerror = error => {
          eventSource.close();
          reject(new Error('EventSource failed: ' + JSON.stringify(error)));
        };
      });
    },
    ValidateFormula: (formula: string,index: number) =>
      instance
        .post<string>('vc/validateFormula', { formula,index })
        .then(res => res.data),
    genFormula: (params: Record<string, any>) =>
      instance
        .get<string>('vc/genFormula', { params })
        .then(res => res.data),
    Eval: (onProgress: EvalAllProgressCallback,id: string): Promise<EvalAllResult> => {
      return new Promise((resolve, reject) => {
        const eventSource = new EventSourcePolyfill(`${baseURL}/vc/Eval/${id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });
    
        eventSource.onmessage = event => {
          const data = JSON.parse(event.data);
          if (data.progress) {
            onProgress(data.progress);
          } else if (data.complete) {
            eventSource.close();
            resolve({ success: true });
          } else if (data.error) {
            eventSource.close();
            reject(new Error(data.error));
          }
        };
        eventSource.onerror = error => {
          eventSource.close();
          reject(new Error('EventSource failed: ' + JSON.stringify(error)));
        };
      });
    },
    one: (id: string) =>
      instance.get<IVirtualColumn>(`/vc/${id}`).then(res => res.data),
    addVirtual: (data: any) =>
      instance.post<any>('/vc', data).then(res => res.data),
    create: (cd: Omit<IVirtualColumn, '_id'>) =>
      instance.post<IVirtualColumn>('/vc', cd).then(res => res.data),
    update: (cd: IVirtualColumn) => {
      return instance
        .put<IVirtualColumn>(`/vc/${cd._id}`, cd)
        .then(res => res.data);
    },
    delete: (id: string) => instance.delete(`/vc/${id}`),
  };

  const cds = {
    all: () =>
      instance.get<ICreativeDataStructure[]>('/cds').then(res => res.data),
    filter: (params: Record<string, any>) =>
      instance
        .get<ICreativeDataStructure[]>('/cds', { params })
        .then(res => res.data),
    one: (id: string) =>
      instance.get<ICreativeDataStructure>(`/cds/${id}`).then(res => res.data),
    create: (cd: Omit<ICreativeDataStructure, '_id'>) =>
      instance.post<ICreativeDataStructure>('/cds', cd).then(res => res.data),
    update: (cd: ICreativeDataStructure) =>
      instance
        .put<ICreativeDataStructure>(`/cds/${cd._id}`, cd)
        .then(res => res.data),
    delete: (id: string) => instance.delete(`/cds/${id}`),
  };

  const moments = {
    search: (params: Record<string, any>) =>
      instance
        .get<IMomentResult[]>('/moments', { params })
        .then(res => res.data),
  };

  const experiments = {
    checkpaid: (urls: string[]) =>
      instance
        .post<{ results: Array<SocialMediaInfo> }>(
          '/paidchecker',
          { urls }
        )
        .then(res => res.data.results),
  };
  
 

  const guidelines = {
    brand: buildGuidelineRouter<IBrandGuideline>('/guidelines/brand'),
    platform: buildGuidelineRouter<IPlatformGuideline>('/guidelines/platform'),
    all: () =>
      instance.get<ICreativeDataStructure[]>('/cds').then(res => res.data),
    add: (data: any) => instance.post<any>('/cds', data).then(res => res.data),
  };

  const creatives = {
    latest: () =>
      instance
        .get<any>('/creatives/latest')
        .then(res => res.data as Record<string, any>),
  };

  const insights = {
    getInsights: (filters: Partial<VideoFilters>) =>
      instance
        .get<Insight[]>('/insights', { params: filters })
        .then(res => res.data),
    getChartData: (insightId: string, filters: VideoFilters) =>
      instance
        .get<ChartData>(`/insights/${insightId}/chart`, { params: filters })
        .then(res => res.data),
    bookmarkInsight: (insightId: String, params: Insight) =>
      instance
        .post<Insight>(`insights/${insightId}/bookmark`, { params })
        .then(res => res.data),
    getBookmarkedInsights: () =>
      axios
        .get<Insight[]>(`${baseURL}/insights/bookmarked`)
        .then(res => res.data),
    removeBookmark: (insightId: string) =>
      axios
        .delete<Insight>(`/insights/${insightId}/bookmark`)
        .then(res => res.data),
  };

  const preflight = {
    all: (params?: Partial<PaginationOptions>) =>
      instance
        .get<PaginatedResult<IPreflightVideo>>('/preflight', { params })
        .then(res => res.data),
    one: (id: string) =>
      instance.get<IPreflightVideo>(`/preflight/${id}`).then(res => res.data),
    create: (video: CreatePreflightVideo & { filepath: string }) =>
      instance.post<IPreflightVideo>('/preflight', video).then(res => res.data),
    createBulk: (file: File, source: Source) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('source', source);
      return instance
        .post<IPreflightVideo[]>('/preflight/bulk', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => res.data);
    },
    upload: (
      file: File,
      onUploadProgress?: AxiosRequestConfig['onUploadProgress']
    ) => {
      const formData = new FormData();
      formData.append('file', file);
      return instance
        .post<{ path: string }>('/preflight/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress,
        })
        .then(res => res.data);
    },
    delete: (id: string) => instance.delete(`/preflight/${id}`),
    scores: {
      brand: (id: string) =>
        instance
          .get<IGuidelineScore>(`/preflight/${id}/scores/brand`)
          .then(res => res.data),
      platform: (id: string) =>
        instance
          .get<IGuidelineScore>(`/preflight/${id}/scores/platform`)
          .then(res => res.data),
    },
  };

  const brands = {
    all: () => instance.get<Brand[]>('/brands').then(res => res.data),
    one: (id: string) =>
      instance.get<Brand>(`/brands/${id}`).then(res => res.data),
  };

  const users = {
    all: () =>
      instance
        .get<WithPerms<WithRoles<User>>[]>('/admin/users')
        .then(res => res.data),
    get: (id: number) =>
      instance
        .get<WithPerms<WithRoles<User>>>(`/admin/users/${id}`)
        .then(res => res.data),
    create: (user: Omit<WithRoles<WithPass<User>>, 'UserID'>) =>
      instance
        .post<WithPerms<WithRoles<User>>>('/admin/users', user)
        .then(res => res.data),
    update: (user: User) =>
      instance
        .put<User>(`/admin/users/${user.UserID}`, user)
        .then(res => res.data),
    delete: (UserID: number) =>
      instance.delete<User>(`/admin/users/${UserID}`).then(res => res.data),
  };

  const roles = {
    all: () =>
      instance.get<WithPerms<Role>[]>('/admin/roles').then(res => res.data),
  };

  function buildStaticFetcher<T extends keyof VideoFilters>(endpoint: string) {
    return (filters?: Partial<Omit<VideoFilters, T>>) =>
      instance
        .get(endpoint, { params: filters })
        .then(res => res.data as VideoFilters[T][]);
  }

  function buildCrudRouter<T extends { _id: string }>(path: string) {
    return {
      all: () => instance.get<T[]>(path).then(res => res.data),
      one: (id: string) =>
        instance.get<T>(`${path}/${id}`).then(res => res.data),
      create: (item: Omit<T, '_id'>) =>
        instance.post<T>(path, item).then(res => res.data),
      update: (item: T) =>
        instance.put<T>(`${path}/${item._id}`, item).then(res => res.data),
      delete: (id: string) => instance.delete(`${path}/${id}`),
    };
  }

  function buildGuidelineRouter<T extends { _id: string }>(path: string) {
    return {
      ...buildCrudRouter<T>(path),
      score: (params?: Partial<VideoFilters>) =>
        instance
          .get<IGuidelineScore>(`${path}/score`, { params })
          .then(res => res.data),
      scoreHistory: (params?: Partial<VideoFilters>) =>
        instance
          .get<IScoreHistory>(`${path}/history`, { params })
          .then(res => res.data),
    };
  }

  const api = {
    creatives,
    videos,
    cds,
    moments,
    experiments,
    vc,
    aiSearch,
    auth: authApi,
    guidelines,
    insights,
    preflight,
    imageAnalysis,
    brands,
    users,
    roles,
  };
  return api;
}
export type Api = ReturnType<typeof buildApi>;
export type ApiError = AxiosError<{ error: string; details?: any }>;
export const parseApiError = (e: ApiError) =>
  e.response?.data.error || e.message;

export const authApi = {
  login: (email: string, password: string) =>
    unauthenticatedInstance
      .post<{ accessToken: string; refreshToken: string }>('/auth/login', {
        email,
        password,
      })
      .then(res => res.data),
  refreshToken: (refreshToken: string) =>
    unauthenticatedInstance
      .post<{ accessToken: string }>('/auth/refresh', { refreshToken })
      .then(res => res.data),
};

export interface Insight {
  id: string;
  content: string;
  tags: [string];
  impact: Number;
  isPositive: Boolean;
  factor: ICreativeDataStructure;
  metric: string;
  brand: string;
  country: string;
  platform: string;
  dateRange: {
    start: Date;
    end: Date;
  };
  isBookmarked: Boolean;
  userId: string;
  numberOfVideos: Number;
}

interface ChartDataPoint {
  factorValue: number;
  performance: number;
}

export interface ChartData {
  factorName: string;
  factorTitle: string;
  factorType: 'category' | 'number';
  metric: string;
  data: ChartDataPoint[];
  trendLine?: { slope: number; intercept: number };
  percentiles?: { [key: string]: ChartDataPoint };
  // insightRange?: { min: number; max: number };
  insightRanges?: { min: number; max: number; performanceDiff: number }[];
}
