import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControlLabel,
  Modal,
  Pagination,
  Paper,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import useCdStructures from '../../contexts/cdStructures';
import {
  ICreativeDataStructure,
  isCds,
  IStructure,
} from '../../models/creativeDataStructure';
import { ApiError } from '../../services/api';
import { getNestedValue } from '../../utils/misc';
import { deepClone } from '../../utils/typing';
import Formula from '../General/FormulaInput';
import CdsForm from './CdsForm';
import { useCdsManager } from './context';
import { StructureSection } from './StructureSection';

const ModalBox = styled(Stack)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 1000,
  background: 'white',
  padding: 20,
  borderRadius: 5,
  maxHeight: '80%',
  overflow: 'auto',
});

const FormWrapper = styled(Box)({
  display: 'flex',
  gap: '20px',
});

const FormSection = styled(Box)({
  flex: 1,
});

const RightSection = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const JsonPreview = styled(Paper)({
  padding: '10px',
  maxHeight: '300px',
  overflow: 'auto',
});


interface JsonPreviewSectionProps {
  showJsonPreview: boolean;
  setShowJsonPreview: React.Dispatch<React.SetStateAction<boolean>>;
  jsonData: any[];
  currentSampleIndex: number;
  setCurrentSampleIndex: React.Dispatch<React.SetStateAction<number>>;
}
const JsonPreviewSection: React.FC<JsonPreviewSectionProps> = ({ 
  showJsonPreview, 
  setShowJsonPreview, 
  jsonData,
  currentSampleIndex,
  setCurrentSampleIndex
}) => (
  <>
    <FormControlLabel
      control={
        <Switch
          checked={showJsonPreview}
          onChange={() => setShowJsonPreview(!showJsonPreview)}
        />
      }
      label="Show JSON Preview"
    />
    {showJsonPreview && (
      <JsonPreview>
        <Typography variant="h6">JSON Preview</Typography>
        {jsonData && (
          <>
            <Formula value={JSON.stringify(jsonData[currentSampleIndex], null, 2)} />
            <Pagination
              count={jsonData.length}
              page={currentSampleIndex + 1}
              onChange={(event, page) => setCurrentSampleIndex(page - 1)}
              color="primary"
              style={{ marginTop: '10px' }}
            />
          </>
        )}
      </JsonPreview>
    )}
  </>
);

interface CdsModalProps {
  title: string;
  children: React.ReactNode;
  onSave: () => void;
  isSaveDisabled: boolean;
}

const CdsModal: React.FC<CdsModalProps> = ({ title, children, onSave, isSaveDisabled }) => (
  <ModalBox>
    <h1>{title}</h1>
    {children}
    <Button onClick={onSave} disabled={isSaveDisabled}>
      Save
    </Button>
  </ModalBox>
);

interface CdsFormModalProps {
  mode: 'edit' | 'create';
  initialCds: Partial<ICreativeDataStructure>;
  close: () => void;
}


const CdsFormModal: React.FC<CdsFormModalProps> = ({ mode, initialCds, close }) => {
  const { updateCds, createCds } = useCdStructures();
  const { samples } = useCdsManager();
  const [error, setError] = useState<ApiError>();
  const [cds, setCds] = useState<ICreativeDataStructure>(
    mode === 'edit' 
      ? deepClone(initialCds as ICreativeDataStructure) 
      : {
          source: initialCds?.source || '',
          name: '',
          title: '',
          category: '',
          icon: 'InfoOutlined',
          order: 99,
          structure: { 
            type: 'string', 
            uiType: 'text',
            showLabels: true 
          },
          display: true,
          description: '',
        }
  );

  const [showJsonPreview, setShowJsonPreview] = useState(false);
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);

  const handleSave = useCallback(() => {
    const action = mode === 'edit' ? updateCds : createCds;
    action(cds).then(close).catch(setError);
  }, [mode, updateCds, createCds, cds, close]);

  const setStructure = useCallback((updater: React.SetStateAction<IStructure>) => {
    setCds(prevCds => ({
      ...prevCds,
      structure: typeof updater === 'function' ? updater(prevCds.structure) : updater,
    }));
  }, []);

  const memoizedSamples = useMemo(() => samples, [samples]);
  const memoizedJsonData = useMemo(() => 
    memoizedSamples.map(sample => getNestedValue(sample, cds.source)),
    [memoizedSamples, cds.source]
  );

  const memoizedCds = useMemo(() => ({
    ...cds,
    structure: cds.structure
  }), [cds]);

  return (
    <CdsModal
      title={`${mode === 'edit' ? 'Edit' : 'Create'} ${cds.source}`}
      onSave={handleSave}
      isSaveDisabled={!isCds(cds)}
    >
      <FormWrapper>
        <FormSection>
          <CdsForm cds={cds} setCds={setCds} samples={memoizedSamples} />
          <JsonPreviewSection
            showJsonPreview={showJsonPreview}
            setShowJsonPreview={setShowJsonPreview}
            jsonData={memoizedJsonData}
            currentSampleIndex={currentSampleIndex}
            setCurrentSampleIndex={setCurrentSampleIndex}
          />
        </FormSection>
        <RightSection>
          <StructureSection
            cds={memoizedCds}
            setStructure={setStructure}
            jsonData={memoizedJsonData[currentSampleIndex]}
            path=''
          /> 
        </RightSection>
      </FormWrapper>
      <ApiErrorComponent error={error} />
    </CdsModal>
  );
};

export const EditModal: React.FC = () => {
  const { editCds, setEditCds } = useCdsManager();
  return (
    <Modal open={!!editCds} onClose={() => setEditCds(null)}>
      <Box>
        {editCds && <CdsFormModal mode="edit" initialCds={editCds} close={() => setEditCds(null)} />}
      </Box>
    </Modal>
  );
};

export const CreateModal: React.FC = () => {
  const { cdNameToCreate, setCdNameToCreate, showCreateModal, setShowCreateModal } = useCdsManager();
  
  const handleClose = () => {
    setCdNameToCreate(null);
    setShowCreateModal(false);
  };

  return (
    <Modal open={showCreateModal} onClose={handleClose}>
      <Box>
        <CdsFormModal 
          mode="create" 
          initialCds={{ source: cdNameToCreate || '' }} 
          close={handleClose} 
        />
      </Box>
    </Modal>
  );
};