import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { getColor } from '../../theme/colors';
import CdsDataGrid from './CdsDataGrid';
import CdsManagerProvider, { useCdsManager } from './context';
import { CreateModal, EditModal } from './modals';

const CdsManager: React.FC = () => {
  return (
    <CdsManagerProvider>
      <CreateModal />
      <EditModal />
      <CdsManagerContent />
    </CdsManagerProvider>
  );
};

const CdsManagerContent: React.FC = () => {
  const { setShowCreateModal } = useCdsManager();

  return (
    <Stack height='100%' spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant='h4'>Structure Manager</Typography>
        
      </Stack>
      <Box flexGrow={1} minHeight={0}>
      <Button
          style={{margin: '20px 0px'}} 
          variant="contained" 
          color="primary" 
          onClick={() => setShowCreateModal(true)}
        >
          New Creative Data
        </Button>
        <CdsDataGrid />
      </Box>
    </Stack>
  );
};

const AbsentCds: React.FC = () => {
  const { absentCd, setCdNameToCreate } = useCdsManager();
  return (
    <Stack direction='row' spacing={1} p={1}>
      {absentCd.map((cd, i) => (
        <Chip
          key={cd}
          label={cd}
          sx={{ bgcolor: getColor(i) }}
          onClick={() => setCdNameToCreate(cd)}
        />
      ))}
    </Stack>
  );
};

export default CdsManager;