import React, { useState } from 'react';
import { RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { KeyValueItem } from '../../models/creativeData';
import { getColor } from '../../theme/colors';
import { Box, Typography } from '@mui/material';

export interface SpiderChartItem {
  name: string;
  value: number;
}

type SpiderChartProps<K extends React.Key, V extends any> = {
  data: KeyValueItem<K, V>[];
} & (V extends number ? {} : { parser: (value: V) => number });



const SpiderChart = <K extends React.Key, V extends any>(
  props: SpiderChartProps<K, V>
) => {
  const { data } = props;
  const parser = 'parser' in props ? props.parser : (v: V) => v as number;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const totalValues = data.reduce((acc, item) => acc + parser(item.value), 0);
  const chartData = data.map(item => ({
    name: String(item.key),
    value: parser(item.value),
    percentage: totalValues > 0 ? (parser(item.value) / totalValues) * 100 : 0,
  }));

  const handleMouseEnter = (data: any, index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <Box style={{ width: '100%', height: 300 }} display={'flex'}>
      <ResponsiveContainer>
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <Radar
            name="Value"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
            onMouseLeave={handleMouseLeave}
          />
          <Tooltip 
            formatter={(value: number, name: string, props: any) => [
              `${props.payload.percentage.toFixed(2)}%`,
              name,
            ]}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SpiderChart;