import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import LoadingRessourcePage from '../../../components/LoadingRes';
import useCdStructures from '../../../contexts/cdStructures';
import { ICreativeDataStructure } from '../../../models/creativeDataStructure';
import useVideoDashboard from '../context';

const CreativesContext = createContext<CdsContextType | null>(null);

export const CreativesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { video } = useVideoDashboard();
  const { creativeData } = video;
  const { visibleCdStructures, visibleCategories } = useCdStructures();
  const [categories, setCategories] = useState<string[]>(visibleCategories);
  const availableCd = visibleCdStructures.filter(cds =>
    categories.includes(cds.category)
  );
  const [selectedCd, setSelectedCd] =
    useState<ICreativeDataStructure[]>(visibleCdStructures);

  useEffect(() => {
    setSelectedCd(prev => prev.filter(cd => visibleCategories.includes(cd.category)));
  }, [visibleCategories]);

  if (!creativeData) return <LoadingRessourcePage resName='Creative Data' />;
  const value = {
    creativeData,
    categories,
    setCategories,
    availableCategories: visibleCategories,
    selectedCd,
    setSelectedCd,
    availableCd,
  };
  return (
    <CreativesContext.Provider value={value}>
      {children}
    </CreativesContext.Provider>
  );
};

interface CdsContextType {
  creativeData: Record<string, any>;
  categories: string[];
  setCategories: React.Dispatch<React.SetStateAction<string[]>>;
  availableCategories: string[];
  selectedCd: ICreativeDataStructure[];
  setSelectedCd: React.Dispatch<React.SetStateAction<ICreativeDataStructure[]>>;
  availableCd: ICreativeDataStructure[];
}

export default function useCreatives() {
  const context = useContext(CreativesContext);
  if (!context)
    throw new Error('useCreatives must be used within a CreativesProvider');
  return context;
}
