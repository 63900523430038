import { ColumnType } from '../models/creativeDataStructure/uiTypes';

export type IVirtualColumn = {
  type?: ColumnType;
  _id?: string;
  name: string;
  title: string;
  description: string;
  formula: string;
};


export const defaultVC = (): IVirtualColumn => ({
  name: '',
  title: '',
  description: '',
  formula: '',
});
