import { Button, Grid, TextField, Typography } from '@mui/material';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import NormalizedSelect, {
  MultipleNormalizedSelect,
} from '../../components/NormalizedSelect';
import { useBrands } from '../../contexts/global';
import { ApiError } from '../../services/api';
import { buildNestedSetState } from '../../utils/misc';
import useUsersMgr, { CreateUserPayload } from './context';

const CreateUser: React.FC<{ onClose?: () => void }> = props => {
  const { onClose = () => {} } = props;
  const { brands, getBrand } = useBrands();
  const { roles, getRole, createUser } = useUsersMgr();
  const [user, setUser] = useState<CreateUserPayload>({
    Email: '',
    Username: '',
    Password: '',
    IsActive: true,
    BrandID: '' as any,
    Roles: [],
  });
  const [error, setError] = useState<ApiError>();
  const submit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      createUser(user).then(onClose).catch(setError);
    },
    [createUser, user]
  );
  const setEmail = buildNestedSetState(setUser, 'Email');
  const setUsername = buildNestedSetState(setUser, 'Username');
  const setPassword = buildNestedSetState(setUser, 'Password');
  const setBrandID = buildNestedSetState(setUser, 'BrandID');
  const setRoles = buildNestedSetState(setUser, 'Roles');
  const setRoleIds = useCallback(
    (roleIds: number[]) =>
      setRoles(roles.filter(r => roleIds.includes(r.RoleID))),
    [roles, setRoles]
  );
  const Permissions = useMemo(
    () =>
      roles
        .filter(r => user.Roles.some(ur => ur.RoleID === r.RoleID))
        .map(r => r.Permissions)
        .flat()
        .filter(
          (curr, i, arr) =>
            arr.findIndex(p => p.PermissionID === curr.PermissionID) === i
        ),
    [roles, user.Roles]
  );

  const valid = user.Email && user.Username && user.Password && user.BrandID;
  return (
    <Grid
      container
      spacing={2}
      component='form'
      onSubmit={submit}
      autoComplete='off'
    >
      <Grid item xs={12}>
        <Typography variant='h5'>Create User</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Email'
          type='email'
          value={user.Email}
          onChange={e => setEmail(e.target.value)}
          variant='standard'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Username'
          value={user.Username}
          onChange={e => setUsername(e.target.value)}
          variant='standard'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Password'
          type='password'
          value={user.Password}
          onChange={e => setPassword(e.target.value)}
          variant='standard'
          fullWidth
          autoComplete='new-password'
        />
      </Grid>
      <Grid item xs={0} sm={6} />
      <Grid item xs={12} sm={6}>
        <NormalizedSelect
          label='Brand'
          value={user.BrandID}
          onChange={setBrandID}
          options={brands.map(({ BrandID }) => BrandID)}
          formatOption={BrandID => getBrand(BrandID)?.BrandName ?? ''}
          parseValue={Number}
          width='100%'
          noAll
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultipleNormalizedSelect
          label='Roles'
          value={user.Roles.map(({ RoleID }) => RoleID)}
          onChange={setRoleIds}
          options={roles.map(({ RoleID }) => RoleID)}
          formatOption={RoleID => getRole(RoleID)?.RoleName ?? ''}
          parseValue={Number}
          width='100%'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          title={Permissions.map(p => p.PermissionName).join(', ')}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Permissions: {Permissions.map(p => p.PermissionName).join(', ')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Button variant='contained' color='error' onClick={onClose} fullWidth>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!valid}
          fullWidth
        >
          Submit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ApiErrorComponent error={error} />
      </Grid>
    </Grid>
  );
};
export default CreateUser;
