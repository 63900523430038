import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import Papa, { ParseResult } from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import FileDropzone from '../../components/FileDropzone';
import NormalizedSelect from '../../components/NormalizedSelect';
import { useAuthenticatedUser } from '../../contexts/auth';
import { IPreflightVideo, Platform, Source, sources } from '../../models';
import { ApiError } from '../../services/api';
import parseCsv from '../../utils/csv';

interface Row {
  name: string;
  platform: Platform;
  brand: string;
  country: string;
  campaign: string;
  objectives: string;
  downloadUrl: string;
  assetId?: string;
}
const BulkCreatePreflight: React.FC<{
  postCreate: (...preflights: IPreflightVideo[]) => void;
}> = ({ postCreate }) => {
  const { api } = useAuthenticatedUser();
  const [file, setFile] = useState<File | null>(null);
  const [source, setSource] = useState<Source>('internal');
  const [parsedRes, setParsedRes] = useState<ParseResult<Row> | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      api.preflight
        .createBulk(file!, source)
        .then(preflights => postCreate(...preflights))
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [api, file, source, postCreate]
  );
  const valid = !!file && !parsedRes?.errors.length;

  const onFileSelect = (file: File) => setFile(file);

  useEffect(() => {
    if (!file) return;
    parseCsv(file, {
      header: true,
      skipEmptyLines: true,
      requiredHeaders: getRequiredHeaders(source),
      validateRow: row => {
        if (source !== 'internal' && !row.assetId) {
          return 'assetId is required';
        }
      },
      complete: setParsedRes,
    });
  }, [file, source]);

  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit}>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        component='a'
        href={csvUrl}
        download={csvFilename}
      >
        Download example CSV
      </Button>
      <FileDropzone
        onFileSelect={onFileSelect}
        accept={['text/csv']}
        file={file}
        onError={() => setFile(null)}
        height={150}
        maxFileSize={50 * 1024 * 1024} // 50 MB
      />
      <NormalizedSelect
        label='Source'
        value={source}
        onChange={setSource}
        options={sources}
        variant='outlined'
        noAll
      />
      {parsedRes && <FilePreview parsedRes={parsedRes} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StyledButton
            variant='contained'
            color='secondary'
            sx={{
              color: 'black',
              bgcolor: 'white',
            }}
            fullWidth
          >
            Cancel
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledButton
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              color: 'white',
              bgcolor: 'black',
            }}
            disabled={!valid || loading}
            fullWidth
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      ></Box>
      <ApiErrorComponent error={error} />
    </Stack>
  );
};
export default BulkCreatePreflight;

const StyledButton = styled(Button)({
  px: 2,
  borderRadius: '10px',
  textTransform: 'none',
});

const FilePreview: React.FC<{ parsedRes: ParseResult<Row> }> = ({
  parsedRes,
}) => {
  const { data, errors, meta } = parsedRes;
  return (
    <Stack spacing={2} width='100%'>
      <Typography variant='h6'>Preview</Typography>
      <Typography variant='body1'>
        {data.length} rows and {meta.fields?.length} columns
      </Typography>
      {errors.length > 0 && (
        <Stack spacing={1}>
          <Typography variant='h6'>Errors</Typography>
          {errors.map((e, i) => (
            <Typography key={i} variant='body1' color='error'>
              {e.row !== undefined && `Row ${e.row + 1}: `}
              {e.message}
            </Typography>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

function getRequiredHeaders(source: Source) {
  const rh: (keyof Row)[] = [
    'name',
    'platform',
    'brand',
    'country',
    'campaign',
    'objectives',
    'downloadUrl',
  ];
  if (source !== 'internal') rh.push('assetId');
  return rh;
}

const csvFilename = 'preflight-extract.csv';
const csvData: Row[] = [
  {
    name: 'Testing preflight',
    platform: 'YouTube',
    brand: 'Lancôme',
    country: 'France',
    campaign: 'idole_new_fragrance',
    objectives: 'CTR,ConversionRate',
    downloadUrl:
      'https://sample-videos.com/video321/mp4/720/big_buck_bunny_720p_1mb.mp4',
    assetId: '123456',
  },
];
const csvContent = Papa.unparse(csvData, { delimiter: ';' });
const csvBlob = new Blob([csvContent], { type: 'text/csv' });
const csvUrl = URL.createObjectURL(csvBlob);
