import { Route, Routes } from 'react-router-dom';
import { BrandGuidelines, PlatformGuidelines } from './All';
import { CreateBrandGuideline, CreatePlatformGuideline } from './Create';
import { BrandGuideline, PlatformGuideline } from './One';

const GuidelinesManagers: React.FC = () => {
  return (
    <Routes>
      <Route path='brand'>
        <Route index element={<BrandGuidelines />} />
        <Route path='new' element={<CreateBrandGuideline />} />
        <Route path=':id' element={<BrandGuideline />} />
      </Route>
      <Route path='platform'>
        <Route index element={<PlatformGuidelines />} />
        <Route path='new' element={<CreatePlatformGuideline />} />
        <Route path=':id' element={<PlatformGuideline />} />
      </Route>
    </Routes>
  );
};
export default GuidelinesManagers;
