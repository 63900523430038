import * as Icons from '@mui/icons-material';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import FunctionsIcon from '@mui/icons-material/Functions';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import SquareIconCard from '../../components/SquareIconCard';
import useCdStructures from '../../contexts/cdStructures';
import { ICreativeDataStructure, IStructure, StructureType, UiType } from '../../models/creativeDataStructure';
import IconPicker, { IconName } from '../General/IconPicker';
import TreeView from '../General/TreeView';
import { useCdsManager } from './context';
import { inferStructure } from './inference';

interface CdsFormProps {
  cds: ICreativeDataStructure;
  setCds: React.Dispatch<React.SetStateAction<ICreativeDataStructure>>;
  samples: any[];
}

const CdsForm: React.FC<CdsFormProps> = ({ cds, setCds, samples }) => {
  const { categories } = useCdStructures();
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { cdStructures } = useCdsManager();
  const [titleError, setTitleError] = useState('');
  const [currentSampleIndex, setCurrentSampleIndex] = useState(0);

  const handleIconSelect = useCallback((iconName: IconName) => {
    setCds(prevCds => ({
      ...prevCds,
      icon: iconName
    }));
    setShowIconPicker(false);
  }, [setCds]);

  const handleTreeSelect = useCallback((path: string) => {
    const newStructure = inferStructure(path, samples[currentSampleIndex]) as IStructure<UiType<StructureType>>;
    setCds(prevCds => ({
      ...prevCds,
      source: path,
      structure: newStructure
    }));
    setOpenDialog(false);
  }, [setCds, samples, currentSampleIndex]);

  const formatDataSource = (source: string) => {
    const parts = source.split('.');
    if (parts.length > 1) {
      return `${parts[0]} > ${parts[parts.length - 1]}`;
    }
    return source;
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    const titleExists = cdStructures.some(structure => 
      structure.title.toLowerCase() === newTitle.toLowerCase()
    );

    if (titleExists) {
      setTitleError('This title already exists. Please choose a unique title.');
    } else {
      setTitleError('');
    }

    setCds(prev => ({ 
      ...prev, 
      title: newTitle, 
      name: newTitle.toLowerCase().replace(/\s+/g, '_') 
    }));
  };

  const handleSampleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentSampleIndex(value - 1);
  };

  return (
    <Stack spacing={2}>
      <Button
        variant="outlined"
        onClick={() => setOpenDialog(true)}
        fullWidth
        style={{ justifyContent: 'flex-start', padding: '13px 14px' }}
      >
        <Typography variant="body1" style={{ marginRight: 'auto' }}>
          {formatDataSource(cds.source) || 'Select Data Source'}
        </Typography>
      </Button>
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>Select Data Source</DialogTitle>
        <DialogContent>
          {samples.length > 0 ? (
            <>
                <Pagination 
                count={samples.length} 
                page={currentSampleIndex + 1} 
                onChange={handleSampleChange} 
                color="primary" 
                style={{ margin:"20px auto", width:"100%" }}
              />
              <TreeView data={samples[currentSampleIndex]} onSelect={handleTreeSelect} />
    
            </>
          ) : (
            <Typography>No data available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" alignItems="center" spacing={2}>
        <Button onClick={() => setShowIconPicker(true)} variant="outlined" style={{height:"55px"}}>
          {cds.icon && Icons[cds.icon as IconName] && React.createElement(Icons[cds.icon as IconName])}
        </Button>

        <TextField
          label='Title'
          value={cds.title}
          onChange={handleTitleChange}
          variant='outlined'
          fullWidth
          error={!!titleError}
          helperText={titleError}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          label='Order'
          type='number'
          value={cds.order}
          onChange={e => setCds(prev => ({ ...prev, order: Number(e.target.value) }))}
          variant='outlined'
          fullWidth
        />
        <TextField
          label='Category'
          value={cds.category}
          onChange={e => setCds(prev => ({ ...prev, category: e.target.value }))}
          variant='outlined'
          fullWidth
        />
      </Stack>
      <IconPicker
        open={showIconPicker}
        onClose={() => setShowIconPicker(false)}
        onSelectIcon={handleIconSelect}
      />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <SquareIconCard
            icon={VisibilityIcon}
            title="Visible"
            isSelected={cds.display}
            onClick={() => setCds(prev => ({ ...prev, display: !prev.display }))}
          />
        </Grid>
        <Grid item>
          <SquareIconCard
            icon={StarIcon}
            title="Highlighted"
            isSelected={cds.highlighted || false}
            onClick={() => setCds(prev => ({ ...prev, highlighted: !prev.highlighted }))}
          />
        </Grid>
        <Grid item>
          <SquareIconCard
            icon={FunctionsIcon}
            title="Is a Factor"
            isSelected={cds.isFactor || false}
            onClick={() => setCds(prev => ({ ...prev, isFactor: !prev.isFactor }))}
          />
        </Grid>
        <Grid item>
          <SquareIconCard
            icon={DashboardOutlined}
            title="Show in Dashboard"
            isSelected={cds.showInDashboard || false}
            onClick={() => setCds(prev => ({ ...prev, showInDashboard: !prev.showInDashboard }))}
          />
        </Grid>
      </Stack>
      <TextField
        label='Description'
        value={cds.description}
        onChange={e => setCds(prev => ({ ...prev, description: e.target.value }))}
        variant='outlined'
        multiline
        rows={3}
      />
    </Stack>
  );
};

export default CdsForm;