import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../../contexts/auth';
import { Platform, Source } from '../../models';
import { Insight } from '../../services/api';

// year to date
const defaultStartDate = new Date(new Date().getFullYear(), 0, 1);
const defaultEndDate = new Date();

interface Filters {
  platform?: Platform;
  brand?: string;
  country?: string;
  source?: Source;
  start?: Date;
  end?: Date;
}

interface AvailableFilters {
  platforms: Platform[];
  brands: string[];
  countries: string[];
}

interface InsightContextType {
  filters: Filters;
  availableFilters: AvailableFilters;
  setFilter: <K extends keyof Filters>(key: K) => (value: Filters[K]) => void;
  insights: Insight[];
  loading: boolean;
  calculateInsights: () => Promise<void>;
}

const InsightContext = createContext<InsightContextType | null>(null);

export const InsightProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { api } = useAuthenticatedUser();
  const [filters, setFilters] = useState<Filters>({
    start: defaultStartDate,
    end: defaultEndDate,
  });
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>({
    platforms: [],
    brands: [],
    countries: [],
  });
  const [insights, setInsights] = useState<Insight[]>([]);
  const [loading, setLoading] = useState(false);

  const setFilter = useCallback(
    <K extends keyof Filters>(key: K) =>
      (value: Filters[K]) =>
        setFilters(f => ({ ...f, [key]: value })),
    []
  );

  useEffect(() => {
    api.videos.platforms({ analyzed: true }).then(platforms => setAvailableFilters(af => ({ ...af, platforms })));
  }, [api]);

  useEffect(() => {
    api.videos.brands({ platform: filters.platform, analyzed: true }).then(brands => setAvailableFilters(af => ({ ...af, brands })));
  }, [api, filters.platform]);

  useEffect(() => {
    api.videos.countries({ brand: filters.brand, platform: filters.platform, analyzed: true }).then(countries => setAvailableFilters(af => ({ ...af, countries })));
  }, [api, filters.brand, filters.platform]);

  const calculateInsights = useCallback(async () => {
    setLoading(true);
    try {
      const insightResults = await api.insights.getInsights(filters);

      const updatedInsights = insightResults.map(insight => ({
        ...insight,
        brand: filters.brand || insight.brand,
        country: filters.country || insight.country,
        platform: filters.platform || insight.platform,
        dateRange: {
          start: filters.start || insight.dateRange?.start,
          end: filters.end || insight.dateRange?.end
        }
      }));
      
      setInsights(updatedInsights);
    } catch (error) {
      console.error('Error calculating insights:', error);
    } finally {
      setLoading(false);
    }
  }, [api, filters]);

  const value: InsightContextType = {
    filters,
    setFilter,
    availableFilters,
    insights,
    loading,
    calculateInsights,
  };

  return (
    <InsightContext.Provider value={value}>
      {children}
    </InsightContext.Provider>
  );
};

export const useInsight = () => {
  const context = useContext(InsightContext);
  if (!context) {
    throw new Error('useInsights must be used within an InsightProvider');
  }
  return context;
};