import { Box, Stack } from '@mui/material';
import React from 'react';
import FiltersBar from './FiltersBar';
import PaginationBar from './PaginationBar';
import Results from './Results';
import { LibraryProvider } from './context';

const Library: React.FC = () => {
  return (
    <LibraryProvider>
      <LibraryInner />
    </LibraryProvider>
  );
};
export default Library;

const LibraryInner: React.FC = () => {
  return (
    <Stack height='100%'>
      <FiltersBar />
      <PaginationBar />
      <Box flexGrow={1} minHeight={0} overflow='auto'>
        <Results />
      </Box>
    </Stack>
  );
};
