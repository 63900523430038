import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Cell, Label, Pie, PieChart, Sector } from 'recharts';
import { KeyValueItem } from '../../models/creativeData';
import { getColor } from '../../theme/colors';

export interface GaugeItem {
  name: string;
  value: number;
}

type StackedGaugeChartProps<K extends React.Key, V extends any> = {
  data: KeyValueItem<K, V>[];
} & (V extends number ? {} : { parser: (value: V) => number });

const StackedGaugeChart = <K extends React.Key, V extends any>(
  props: StackedGaugeChartProps<K, V>
) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  try {
    const { data } = props;
    const parser = 'parser' in props ? props.parser : (v: V) => v as number;


  const totalValues = data.reduce((acc, item) => acc + parser(item.value), 0);
  const percentage = data
    .filter(item => item.key)
    .map(item => ({
      ...item,
      value: (parser(item.value) / totalValues) * 100,
    }));

  const chartData = percentage.map(item => ({
    name: String(item.key),
    value: item.value,
  }));

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const onPieLeave = () => {
    setActiveIndex(-1);
  };

  const renderActiveShape = (props: any) => {
    try {
      const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        cornerRadius,
      } = props;

      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius + 3}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
            cornerRadius={cornerRadius}
          />
        </g>
      );
    } catch (error) {
      return (
        <text x={150} y={120} textAnchor="middle" fill="red">
          Error rendering chart
        </text>
      );
    }
  };

  const centerLabel =
    activeIndex !== -1
      ? `${chartData[activeIndex].value.toFixed(1)}%`
      : `${chartData[0].value.toFixed(1)}%`;

  const centerName =
    activeIndex !== -1
      ? `${chartData[activeIndex].name}`
      : `${chartData[0].name}`;

  return (
    <>
      <ChartContainer>
        <PieChart width={300} height={150}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={chartData}
            cx={150}
            cy={120}
            innerRadius={70}
            outerRadius={90}
            startAngle={180}
            endAngle={0}
            paddingAngle={1}
            cornerRadius={5}
            dataKey='value'
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieLeave}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
            <Label
              value={centerName}
              position='centerTop'
              dy={-30}
              fill='#555'
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
              }}
            />
            <Label
              value={centerLabel}
              position='center'
              dy={0}
              fill='#000000'
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'Arial, sans-serif',
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
      <Legend>
        {percentage.length > 1
          ? percentage.map((item, index) => (
              <LegendItem key={index}>
                <LegendColor color={getColor(index)} />
                <Typography variant='body2'>{String(item.key)}</Typography>
              </LegendItem>
            ))
          : null}
      </Legend>
    </>
    );
  } catch (error) {
    return <div>Error rendering chart</div>;
  }
};

const ChartContainer = styled(Box)({
  position: 'relative',
  width: '300px',
  height: '150px',
  margin: '0 auto',
});

const Legend = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: '10px',
});

const LegendItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '15px',
});

const LegendColor = styled(Box)<{ color: string }>(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '4px',
}));

export default StackedGaugeChart;
