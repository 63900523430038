import { Box, Grid } from '@mui/material';
import React from 'react';
import VidCard from '../../components/VidCard';
import useLibrary from './context';

const Results: React.FC = () => {
  const { results, displayMode, isLoading } = useLibrary();

  return (
    <Grid container spacing={3} p={3}>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          Loading Data...
        </Box>
      ) : results.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          No data to display
        </Box>
      ) : (
        results.map(result => (
          <Grid
            item
            key={result._id}
            xs={12}
            sm={displayMode === 'grid' ? 6 : 12}
            md={displayMode === 'grid' ? 4 : 6}
            lg={displayMode === 'grid' ? 3 : 4}
          >
            <VidCard metadata={result.metadata} id={result._id} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default Results;
